import React, { Component } from 'react';
import debounce from 'lodash/debounce';

class PaginationNumbering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageValue : this.props.currentPage,
      sendLastPageValue : this.props.sendLastPage,
      blockPrevBtn : false,
      blockNextBtn : false
    };
    this.debouncedJumpToPageNumber = debounce(this.jumpToPageNumber.bind(this), 1500);
  }

  pagefunction = (type) => {
    this.setState(prevState => {
        let newValue;
        let blockPrevBtn = false;
        let blockNextBtn = false;
        if (type === 'showvalue' || type === 'searchExample') {
            newValue = 1;
        } else if (type === 'nextPage') {
            newValue = parseInt(prevState.currentPageValue) < parseInt(prevState.sendLastPageValue) ? parseInt(prevState.currentPageValue) + 1 : parseInt(prevState.sendLastPageValue);
            blockNextBtn = newValue === parseInt(prevState.sendLastPageValue);
        } else {
            newValue = parseInt(prevState.currentPageValue) > 1 ? parseInt(prevState.currentPageValue) - 1 : 1;
            blockPrevBtn = newValue === 1;
        }
        this.props.returnPagenumber(newValue);
        return { currentPageValue: newValue, blockPrevBtn, blockNextBtn };
    });
  }

  jumpToPageNumber = (value) => { this.props.returnPagenumber(value); }

  handleInput = (e) => {
    const value = e.target.value;
    let blockPrevBtn = false;
    let blockNextBtn = false;
    const numericValue = Number(value);   
    if (value && value != 0 && (numericValue <= this.props.sendLastPage && !isNaN(numericValue))) {
      this.setState({ currentPageValue: value, blockPrevBtn, blockNextBtn });
      this.debouncedJumpToPageNumber(numericValue);
    } else {
      this.setState({ currentPageValue: '' });
      e.target.value = ''; 
    }
  }

  render() {
    return (
        <div id="tableFooterHideShowId" className="py-3 d-flex justify-content-between align-items-center">
        <div id="lineToshow" className="d-flex align-items-center font-weight-semibold mb-0 text-dark text-sm">
          <span>Page : &nbsp;</span>
            <input 
              onInput={this.handleInput} 
              className="page-link font-weight-bold text-center" 
              type="number" 
              name="" 
              value={this.state.currentPageValue}
              id="currentPageNo" 
              style={{ width: '7rem', height: '2rem' }}
            />
          <span>&nbsp; of {this.props.sendLastPage}</span>
        </div>
        <div className="ms-auto">
            <button disabled={this.state.blockPrevBtn} className="mx-1 btn btn-sm btn-dark mb-0" id="prevPageBtn" onClick={() => this.pagefunction('prevPage')}>Previous</button>
            <button disabled={this.state.blockNextBtn} className="mx-1 btn btn-sm btn-dark mb-0" id="nextPageBtn" onClick={() => this.pagefunction('nextPage')}>Next</button>
        </div>
    </div>
    );
  }
}

export default PaginationNumbering;
