import React from 'react';
import { withRouter } from 'react-router';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageStatus } from 'enums';
import { Show } from 'Layout';
import { PartnersAPI } from "../../API";

type State = {
    data: any | null,
    status: string,
    error: string | null,
};

class Details extends React.Component<any, State> {
    static defaultProps = {
        languageId: null,
        hideMenu: false,
        onUpdate: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            status: PageStatus.None,
            error: null,
        };
    }

    componentDidMount() {
        if (!!this.props.id) {
            this.fetch();
        }
    }

    fetch() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.props.id) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return PartnersAPI.getClientsDetails(this.props.id);
            })
            .then((client) => {
                if (!!client) {
                    this.setState({ data: client, status: PageStatus.Loaded });
                }
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    componentWillUnmount(): void {
        this.setState({
            data: [],
            status: PageStatus.None,
            error: null,
        })
    }

    render() {
        return (
            <>
                <Modal
                    centered
                    size="lg"
                    backdrop="static"
                    onHide={this.props.onClose}
                    show
                    style={{ zIndex: 1201 }}
                >
                    <Modal.Header closeButton>
                        <h5 className="mb-0 mt-1">Details</h5>
                        <div
                            className="d-flex justify-content-end w-100 mr-2"
                        >
                            <button
                                type="button"
                                onClick={() => this.props.onUpdate()}
                                className="btn-sm btn-primary mr-2"
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'edit']}
                                    className="mr-2"
                                />
                                Update
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>

                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>

                        <Show when={true}>
                            <div className="row">
                                <div className="col">
                                    <strong>Company Name: </strong>
                                    {this.state.data?.name}
                                </div>
                                <div className="col">
                                    <strong>Contact Name: </strong>
                                    {this.state.data?.contactName}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <strong>Number: </strong>
                                    {this.state.data?.number}
                                </div>
                                <div className="col">
                                    <strong>Email: </strong>
                                    {this.state.data?.email}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <strong>State: </strong>
                                    {this.state.data?.state}
                                </div>
                                <div className="col">
                                    <strong>City: </strong>
                                    {this.state.data?.city}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <strong>Zip Code: </strong>
                                    {this.state.data?.zipCode}
                                </div>
                                <div className="col">
                                    <strong>Address: </strong>
                                    {this.state.data?.address}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <strong>Country: </strong>
                                    {this.state.data?.country}
                                </div>
                                <div className="col">
                                </div>
                            </div>
                            <Alert
                                variant="danger"
                                show={this.state.status === PageStatus.Error}
                            >
                                {this.state.error}
                            </Alert>
                        </Show>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const DetailsWithRouter = withRouter(Details);

export { DetailsWithRouter as Details };
