import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import './sass/Main.scss';
import {connect} from "react-redux";
import NetworkDetector from "./Layout/NetworkDetector";
import Admin from "./Layout/Admin";
import {PrivateRouteWithRedux} from "./PrivateRoute";
import { VerificationEmailScreensWithState} from "./Components/Auth/VerificationEmailScreen";
import {AuthRouter} from "./Components/Auth/AuthRouter";
import Terms from "./Components/Legal/Terms";
import TermsHi from "./Components/Legal/TermsHi";
import PrivacyPolicyHi from 'Components/Legal/PrivacyPolicyHi';
import Privacy from "./Components/Legal/Privacy";
import Faq from "./Components/Legal/Faq";
import FaqHi from "./Components/Legal/FaqHi";
import Support from "./Components/Legal/Support";
import Support_hi from "./Components/Legal/Support_hi";
import CookiePolicyHi from 'Components/Legal/CookiePolicyHi';
import CookiePolicy from 'Components/Legal/CookiePolicy';
import RewardsProgramTermsHi from 'Components/Legal/RewardsProgramTermsHi';
import RewardsProgramTerms from 'Components/Legal/RewardsProgramTerms';
import SurveyCompletedCallBackPage from "./Components/SurveyCallbackPages/SurveyCompletedCallBackPage";
import SurveySpeederLimitTerminate from "./Components/SurveyCallbackPages/SurveySpeederLimitTerminate";
import SurveyOverQuotaCallbackPage from "./Components/SurveyCallbackPages/SurveyOverQuotaCallbackPage";
import SurveyTerminatedCallbackPage from "./Components/SurveyCallbackPages/SurveyTerminatedCallbackPage";
import SurveyQualityTerminatedCallbackPage from "./Components/SurveyCallbackPages/SurveyQualityTerminatedCallbackPage";
import {LinkViewWithState} from "./Components/Referrals/LinkView";
import { requestForToken, foreGroundMessage } from './firebase';
import PartnerSurvey from "./Components/Partners/PartnerSurvey";
import {ResetPasswordFormWithState} from "./Components/Auth/UpdateResetPasswordForm";
import SurveyAttemptMessage, {SurveyAttemptMessageWithState} from "./Components/Surveys/SurveyAlreadyAttempted";
import {SurveyUnavailableMessageWithState} from "./Components/Surveys/SurveyUnavailable";
import SurveyHold from 'Components/SurveyHold/SurveyHold';
import PreScreenQuesPage from 'Components/PreScreenQuesPage/PreScreenQuesPage';

requestForToken();
foreGroundMessage();
class App extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      logoutTimer : null,
    }
  }

  componentDidMount() {
      window.addEventListener('mousemove', this.handleActivity);
      window.addEventListener('keypress', this.handleActivity);
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
      this.handleActivity();
  }

  componentWillUnmount() {
      window.removeEventListener('mousemove', this.handleActivity);
      window.removeEventListener('keypress', this.handleActivity);
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      this.handleActivity();
    }
  };

  handleActivity = () => {
    // if(this.props.adminUser.adminUser.role == "panelist"){
      this.clearLogoutTimer();
      this.startLogoutTimer();
    // }
  };

  startLogoutTimer = () => {
    this.setState((prev)=>{
      let timerId = setTimeout(() => {
        const SESSION_EXPIRY_MS = 10 * 60 * 9;
        const lastUserActivity: any = localStorage.getItem("lastUserActivity");
        const now: any = Date.now();
        if (lastUserActivity && now - lastUserActivity > SESSION_EXPIRY_MS) {
          localStorage.clear();
          window.location.href = '/';
        } else {
          localStorage.setItem("lastUserActivity", now);
        }
      }, 10 * 60 * 1000);
      return {logoutTimer: timerId}
    })
  };

  clearLogoutTimer = () => {
    clearTimeout(this.state.logoutTimer);
  };

  render() {
    return (
      <Router basename='/'>
        <NetworkDetector />
        <Switch>
          <Route path='/PreScreenQues' component={PreScreenQuesPage} />
            <Route path='/surveys/completed' component={SurveyCompletedCallBackPage} />
            <Route path='/surveys/speederLimitTerminated' component={SurveySpeederLimitTerminate} />
            <Route path='/surveys/overquota' component={SurveyOverQuotaCallbackPage} />
            <Route path='/surveys/terminate' component={SurveyTerminatedCallbackPage} />
            <Route path='/surveys/qualityterminate' component={SurveyQualityTerminatedCallbackPage} />
            <Route path='/partner' component={PartnerSurvey} />
            <Route path='/referrals/view/:userId' component={LinkViewWithState} />
            <Route path="/auth" component={AuthRouter} />
            <Route path="/survey-attempted-message" component={SurveyAttemptMessageWithState} />
            <Route path="/survey-unavailable-message" component={SurveyUnavailableMessageWithState} />
            <Route path="/verify-screen" component={VerificationEmailScreensWithState} />
            {/* <Route path='/privacy-policy' component={PrivacyPolicy} /> */}
            <Route path='/terms-hi' component={TermsHi} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy-policy-hi' component={PrivacyPolicyHi} />
            <Route path='/privacy-policy' component={Privacy} />
            <Route path='/faq' component={Faq} />
            <Route path='/faq-hi' component={FaqHi} />
            <Route path='/survey-hold' component={SurveyHold} />
            <Route path='/support' component={Support} />
            <Route path='/support-hi' component={Support_hi} />
            <Route path='/rewards-program-terms-hi' component={RewardsProgramTermsHi} />
            <Route path='/rewards-program-terms' component={RewardsProgramTerms} />
            <Route path='/cookie-policy-hi' component={CookiePolicyHi} />
            <Route path='/cookie-policy' component={CookiePolicy} />
            <Route path='/reset-password/:token' component={ResetPasswordFormWithState} />
            <PrivateRouteWithRedux path="/admin" component={Admin} />
            <PrivateRouteWithRedux path="/" component={Admin} />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  adminUser: state.adminUser, 
});

const AppWithState = connect(mapStateToProps)(App);

export { AppWithState as App }
