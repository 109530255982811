import * as _ from 'lodash';

import { api } from '../axiosConfig';
import {partnersURL, clientUrl} from "../Utils/urls";

export class PartnersAPI {
    static getPartners(
        limit = 10000,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${partnersURL}/getAll/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static getAllPartnerSurveys(
        id ,
    ): Promise<any[]> {
        return api.post(`${partnersURL}/getAllPartnerSurveys/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static createPartners(values: any): Promise<any> {
        const data = {
            name: values.name,
            description: values.description,
            successUrl: values.successUrl,
            overQuotaUrl: values.overQuotaUrl,
            disqualifiedUrl: values.disqualifiedUrl,
            badTerminatedUrl: values.badTerminatedUrl,
        };
        return api.post(`${partnersURL}/create`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static updatePartner(values: any, id: any): Promise<any> {
        const data = {
            name: values.name,
            description: values.description,
            successUrl: values.successUrl,
            overQuotaUrl: values.overQuotaUrl,
            disqualifiedUrl: values.disqualifiedUrl,
            badTerminatedUrl: values.badTerminatedUrl,
        };
        return api.post(`${partnersURL}/update/${id}`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static createOnthebasisofSurvey(payload: { userAuthId: string; } & any): Promise<any> {
        // const data = {
        //     name: values.name,
        //     description: values.description,
        //     surveyId: values.surveyId,
        //     patnerId: values.patnerId,
        //     successUrl: values.successUrl,
        //     overQuotaUrl: values.overQuotaUrl,
        //     disqualifiedUrl: values.disqualifiedUrl,
        //     badTerminatedUrl: values.badTerminatedUrl,
        // };
        return api.post(`${partnersURL}/createOnthebasisofSurvey`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getUrlOnTheSurveyBasis(partnerId: any , surveyId): Promise<any> {
        const data = {
            partnerId, surveyId,
        };
        return api.post(`${partnersURL}/getUrlOnTheSurveyBasis`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static getOne(id: any): Promise<any> {
        return api.post(`${partnersURL}/getOne/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }
 
    static deletepartnerurl(id: string, surveyId: string): Promise<any> {
        const payload = {
            partnerId: id, surveyId,
        };
        return api.post(`${partnersURL}/deletepartnerurl`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error deleting partner:', error.message);
                throw error;
            });
    }
 
    static createPartnerParameters(data): Promise<any> {
        return api.post(`${partnersURL}/createPartnerParameters`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error create parameter:', error.message);
                throw error;
            });
    }
 
    static createClient(data): Promise<any> {
        return api.post(`${clientUrl}/create`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error create client:', error.message);
                throw error;
            });
    }
 
    static getAllClients(
        limit: any,
        pagenumber: any,
    ): Promise<any> {
        return api.post(`${clientUrl}/getAll/${limit}/${pagenumber}`, {})
            .then((res) => {
                return _.get(res, 'data', {});
            })
            .catch((error) => {
                console.error('Error get client:', error);
                throw error;
            });
    }
 
    static getClientsDetails(
        id: string,
    ): Promise<any> {
        return api.post(`${clientUrl}/getById/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error get client details:', error);
                throw error;
            });
    }
 
    static updateClientsDetails(
        payload: any,
        id: string,
    ): Promise<any> {
        return api.post(`${clientUrl}/updatedetails/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error update client details:', error);
                throw error;
            });
    }
 
    static deleteClientsDetails(
        id: string,
    ): Promise<any> {
        return api.post(`${clientUrl}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error delete client details:', error);
                throw error;
            });
    }

}
