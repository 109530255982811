export function checkStrongPassword(elem){
    console.log("elem.value = ",elem.value);
    let capitalLatterCheck = /(?=.*[A-Z])/;
    let smallLatterCheck = /(?=.*[a-z])/;
    let numericValCheck = /(?=.*[0-9])/;
    let specialCharCheck = /([^A-Za-z0-9])/;
    let lengthCheck = /(?=.{8,})/;
    let checkingVarObj = { "capitalLatterCheck":"", "numericValCheck":"", "smallLatterCheck":"", "specialCharCheck":"", "lengthCheck":"" };
    checkingVarObj.capitalLatterCheck = (capitalLatterCheck.test(elem.target.value)) ;
    checkingVarObj.smallLatterCheck = (smallLatterCheck.test(elem.target.value)) ;
    checkingVarObj.numericValCheck = (numericValCheck.test(elem.target.value)) ;
    checkingVarObj.specialCharCheck = (specialCharCheck.test(elem.target.value)) ;
    checkingVarObj.lengthCheck = (elem.target.value.length > 7) ;
    return checkingVarObj;
  }

export function checkNumberValidation(elem){
    if(!/^\d*$/.test(elem.target.value)){ return true; }
    else{ return false; }
}

export function checkEmailValidation(elem){
  let emailRegExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(!emailRegExp.test(elem.target.value)){
    return true;
  }else{
    return false;
  }
}