import React from 'react';
import {
    Field,
    reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { MasterDataAPI } from 'API';
import { checkNumberValidation } from 'Helper/Validations';
import { PartnersAPI } from 'API';
import { CountriesAPI } from 'API/CountriesAPI';

export type FormValue = {
    "name": string,
};

class Form extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            name: '',
            country: '',
            address: '',
            city: '',
            state: '',
            selectedCountryOption: [],
            number: '',
            email: '',
            zipCode: '',
            contactName: '',
            datails: '',
        };
    }

    componentDidMount() {
        if (!!this.props.id) {
            this.fetchDetails();
        }
        this.fetchCountryList();
    }

    fetchDetails() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.props.id) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return PartnersAPI.getClientsDetails(this.props.id);
            })
            .then((datails) => {
                this.initializeValues(datails);
                this.setState({
                    datails,
                    status: PageStatus.Loaded,
                });
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    formValues() {
        return {
            name: this.state.name,
            country: this.state.country,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            number: this.state.number,
            email: this.state.email,
            zipCode: this.state.zipCode,
            contactName: this.state.contactName,
        };
    }

    initializeValues(data) {
        return this.setState({
            name: data.name,
            country: data.country,
            address: data.address,
            city: data.city,
            state: data.state,
            number: data.number,
            email: data.email,
            zipCode: data.zipCode,
            contactName: data.contactName,
        });
    }
    onSubmit() {
        if (!this.props.id) {
            return this.create();
        }
        return this.update();
    }

    create() {
        const valuesIn = this.formValues();
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => PartnersAPI.createClient(valuesIn))
            .then((data) => {
                this.setState({ status: PageStatus.Submitted });
                return this.props.onSubmit(data.id);
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    update() {
        const valuesIn = this.formValues();
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => PartnersAPI.updateClientsDetails(valuesIn, this.props.id))
            .then((data) => {
                this.setState({ status: PageStatus.Submitted });
                return this.props.onSubmit(data.id);
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    fetchCountryList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => MasterDataAPI.countryList('10'))
            .then((countries) => {
                const options = countries.map(country => ({
                    label: country.name,
                    value: country.name,
                    shortName: country.shortName,
                }));
                options.sort((a, b) => {
                    if (a.label < b.label) { return -1; }
                    if (a.label > b.label) { return 1; }
                    return 0;
                });
                this.setState({ countries: options, status: PageStatus.Loaded });
                let changedVariable = this.state.country;
                if (this.state.country) {
                    const option = options.filter(item => item.label === changedVariable);
                    this.setState({ selectedCountryOption: option, country: { name: option[0].label, shortName: option[0].shortName }, });
                } else {
                    this.setState({ country: { name: options[75].label, shortName: options[75].shortName }, selectedCountryOption: options[75] });
                }
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    handleCountryChange = async (selectedCountryOption) => {
        let countryName = { name: selectedCountryOption.label, shortName: selectedCountryOption.shortName };
        this.setState({ country: countryName, selectedCountryOption });
    };

    checkNumValidation(e, type, length, value) { (!checkNumberValidation(e) && value.length <= parseInt(length)) && this.setState({ [type]: value.trim() }, () => { this.handleZipCodeChange(e) }) }

    fetchStateAndCitiesByZipCodeOnChange(code): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loaded }))
            .then(() => CountriesAPI.getAllCitiesAndStateBasedOnZipCode(1000, code))
            .then((states: any) => {
                const options = states.state.map(state => ({
                    label: this.props.language === 'hi' ? state.hindi : state.name,
                    value: state.title
                }));
                options.sort((a, b) => {
                    if (a.label < b.label) { return -1; }
                    if (a.label > b.label) { return 1; }
                    return 0;
                });
                const optionsCities = states.cities.map(city => ({
                    label: this.props.language === 'hi' ? city.hindi : city.name,
                    value: city.title
                }));
                options.sort((a, b) => {
                    if (a.label < b.label) { return -1; }
                    if (a.label > b.label) { return 1; }
                    return 0;
                });
                this.setState({ states: options, cities: optionsCities, status: PageStatus.Loaded });
                if (options.length > 0) {
                    this.setState({ state: options[0].label });
                }
                if (optionsCities.length > 0) {
                    this.setState({ city: optionsCities[0].label });
                }
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    handleZipCodeChange = (event) => {
        const { value } = event.target;
        if (value.length === 6) {
            this.fetchStateAndCitiesByZipCodeOnChange(value);
        } else {
            this.setState({ state: '', });
            this.setState({ city: '', });
        }
    };

    reset() {
        return this.setState({
            name: "",
            country: "",
            address: "",
            city: "",
            state: "",
            selectedCountryOption: this.state.countries[75],
            number: '',
            email: '',
            zipCode: '',
            contactName: '',
        });
    }

    render() {
        return (
            <Modal
                centered
                size="lg"
                backdrop="static"
                onHide={this.props.onClose}
                show={this.props.show}
                style={{ zIndex: 1201 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {!!this.props.id ? "Update " : "Add "} Client Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <form onSubmit={this.props.handleSubmit(
                        (event) => this.onSubmit(),
                    )}
                    >

                        <div className="row">
                            <div className="col">
                                <label htmlFor="name">
                                    Company Name*
                                </label>
                                <input
                                    className="form-control"
                                    id='name'
                                    name='name'
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="contactName">
                                    Contact Name*
                                </label>
                                <input
                                    className="form-control"
                                    id='contactName'
                                    name='contactName'
                                    onChange={(e) => this.setState({ contactName: e.target.value })}
                                    value={this.state.contactName}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="number">
                                    Contact Number*
                                </label>
                                <input
                                    className="form-control"
                                    id='number'
                                    name='number'
                                    onChange={(e) => this.checkNumValidation(e, "number", 10, e.target.value)}
                                    value={this.state.number}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="email">
                                    Company Email*
                                </label>
                                <input
                                    className="form-control"
                                    id='email'
                                    name='email'
                                    type='email'
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    value={this.state.email}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="zipCode">
                                    Zip Code*
                                </label>
                                <input
                                    className="form-control"
                                    id='zipCode'
                                    name='zipCode'
                                    onChange={(e) => this.checkNumValidation(e, "zipCode", 6, e.target.value)}
                                    maxLength={6}
                                    value={this.state.zipCode}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="address">
                                    Company Address*
                                </label>
                                <input
                                    className="form-control"
                                    id='address'
                                    name='address'
                                    onChange={(e) => this.setState({ address: e.target.value })}
                                    value={this.state.address}
                                    placeholder="Enter..."
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="state">
                                    State*
                                </label>
                                <input
                                    className="form-control"
                                    id='state'
                                    name='state'
                                    onChange={(e) => this.setState({ state: e.target.value })}
                                    value={this.state.state}
                                    placeholder="Enter..."
                                    readOnly={this.state.zipCode.length < 6}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="city">
                                    City*
                                </label>
                                <input
                                    className="form-control"
                                    id='city'
                                    name='city'
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                    value={this.state.city}
                                    placeholder="Enter..."
                                    readOnly={this.state.zipCode.length < 6}
                                    required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor='country'>Country*</label>
                                <Select
                                    name='countryTitle'
                                    id='countryTitle'
                                    onChange={this.handleCountryChange}
                                    value={this.state.selectedCountryOption}
                                    options={this.state.countries}
                                    required
                                />
                            </div>
                        </div>
                        <hr />
                        <Alert variant="danger" show={!!this.state.error} className="mt-2">
                            {this.state.error}
                        </Alert>

                        <div className="d-flex align-items-center mt-2">
                            <button
                                type="submit"
                                className="btn btn-primary mr-3"
                            >
                                Submit
                            </button>

                            <button
                                type="button"
                                disabled={false}
                                onClick={() => this.reset()}
                                className="btn btn-light mr-3"
                            >
                                Reset
                            </button>

                            <Show when={this.state.status === PageStatus.Submitting}>
                                <Spinner animation="border" variant="primary" />
                            </Show>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        );
    }
}

const FormRedux = reduxForm<FormValue, any>({
    form: 'countryForm',
})(Form);


const FormWithRouter = withRouter(FormRedux);

export { FormWithRouter as Form };
