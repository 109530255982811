import * as _ from 'lodash';
import { api } from '../axiosConfig';
import {
    createBasicProfileURL,
    getProfile,
    loginURL,
    redemptionRequest, redemptionRespondentRequestURL,
    referralsUrl,
    registrationURL, rewardsAPIURL,
    rewardsURL, surveysURL,
    usersListURL, userURL
} from '../Utils/urls';

let tokenValueLogin: any = null;
export class AuthAPI {
    static async login(
        username: string,
        password: string,
        registerType: string,
        language: string
    ): Promise<any> {
        return api.post(loginURL, { email: username, password, registerType, language, loginType:"web" })
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async profile(
        userId: string,
    ): Promise<any> {
        return api.post(`${getProfile}/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async uploadProfile(
        payload: { userAuthId: string; } & any
    ): Promise<any> {
        return api.post(`${userURL}/uploadProfile`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async registration(
        body: any,
    ): Promise<any> {
        body.loginType = "web";
        const header = {
            Authorization: `${tokenValueLogin}`,
        };
        return api.post(registrationURL, body, { headers: header })
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async continueWithMobile(
        data: any
    ): Promise<any> {
        data.loginType = "web";
        return api.post(`${userURL}/continueWithMobile`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async resendOtp(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/resendOtp`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async verifyMobileOtp(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verify-mobile`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error in verify Mobile Otp:', error);
                throw error;
            });
    }
   
    static async sentOTPForapproval(
        data: any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/sentOTPForapproval`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async verifyOTPForApproval(
        data: any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/verifyOTPForApproval`, data)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error in verify Mobile Otp:', error);
                throw error;
            });
    }

    static async createBasicProfile(
        body: any,
        userId: any,
        language: any,
    ): Promise<any> {
        return api.post(`${createBasicProfileURL}/${userId}?language=${language}`, body)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async redemptionList(
        limit: any,
        currentPageNumber: string,
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRequest}/${limit}/${currentPageNumber}`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async approveRedemptionRequest(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/approveRequest`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async manualApprovedBulk(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/manualBulkApprove`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async xoxoApprovedBulk(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/xoxoBulkApprove`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async RejectRequestBulk(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/RejectRequestBulk`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async rejectRedemptionRequest(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/rejectRequest`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    // static async rewardsList(
    //     limit : number,
    //     pagenumber: number,
    // ): Promise<any> {
    //     return api.post(`${rewardsURL}/${limit}/${pagenumber}`)
    //         .then((res) => {
    //             return _.get(res, 'data', []);
    //         });
    // }
    static async rewardsList(
        limit: number,
        pageNumber: number,
        filters: any = {}
    ): Promise<any> {
        const payload = {
            "rewardfrom": filters.rewardfrom,
            "rewardto": filters.rewardto,
            "rewardType": filters.rewardType,
        };

        try {
            const res = await api.post(`${rewardsURL}/${limit}/${pageNumber}`, payload);
            return _.get(res, 'data', []);
        } catch (error) {
            console.error("Failed to fetch rewards:", error);
            return { data: [], totalCount: 0, totalPage: 0 };
        }
    }

    static async rewardsListexport(): Promise<any> {
        try {
            const res = await api.post(`${rewardsURL}/All/1`, {});
            return _.get(res, 'data.data', {});
        } catch (error) {
            console.error("Error fetching rewards list:", error);
            throw error;
        }
    }

    static getAllByUserId(
        limit = 100000,
        userId,
    ): Promise<any[]> {
        return api.post(`${rewardsAPIURL}/getAllByUserId/${userId}/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static ResetPasswordLink(email, language): Promise<any> {
        return api.post(`${userURL}/reset-password?language=${language || 'en'}`, { email: email })
            .then((res) => {
                return _.get(res, 'data.message', {});
            });
    }

    static UpdateNewPassword(token, password): Promise<any> {
        return api.post(`${userURL}/Updatenew-password/${token}`, { password: password })
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static ChangePassword(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${userURL}/change-password`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static UnSubscribeUser(userId): Promise<any> {
        return api.post(`${userURL}/unSubscribeUser/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static requestForDelete(userId): Promise<any> {
        return api.post(`${userURL}/permanentlyDelete/${userId}/user`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static userNotifications(userId): Promise<any> {
        return api.post(`${userURL}/userNotifications/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }
    static appInbox(userId): Promise<any> {
        return api.post(`${userURL}/userNotifications/${userId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static deleteActions(userId, action): Promise<any> {
        return api.post(`${userURL}/deleteActions/${userId}/${action}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async usersList(
        limit: any,
        pagenumber: number,
        payload: { userAuthId: string; } & any,
        type: string,
    ): Promise<any> {
        return api.post(`${usersListURL}/list/${limit}/${pagenumber}/${type}`, payload)
            .then((res) => {
                return _.get(res, 'data', []);
            });
    }

    static async allPanelists(
        payload: { userAuthId: string; } & any,
        limit: any,
        currentPageNumber: number
    ): Promise<any> {
        return api.post(`${usersListURL}/allPanelist/${limit}/${currentPageNumber}`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }


    //Referrals
    static async referralsList(
        limit: any,
        currentPageNumber: number,
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/getAll/${limit}/${currentPageNumber}`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }


    static async createReferralRequest(
        payload: { userAuthId: string; } & any,
        language: any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/create?language=${language}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async createBulkReferralRequest(
        payload: { userAuthId: string; } & any,
        language: any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/bulkCreateReferrals?language=${language}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async getRemainingReferalCount(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${referralsUrl}/getRemainingReferalCount`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async referralsListByUserId(
        limit: number,
        userId: string
    ): Promise<any> {
        return api.post(`${referralsUrl}/getAllUserReferrals/${userId}/${limit}`, {})
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static async NewcontinueWithMobile(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verifyIntity`, data)
            .then((res) => {
                return res.data;
            });
    }


    static async newverifyMobile(
        data: any
    ): Promise<any> {
        return api.post(`${userURL}/verifyIntityOtp`, data)
            .then((res) => {
                tokenValueLogin = res.data.message.data;
                return res.data;
            });
    }

    static async ApprovePendingfinance(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/ApprovePendingfinance`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async manualBulkApproveAdmin(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/manualBulkApproveAdmin`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static async xoxoBulkApproveadmin(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/xoxoBulkApproveadmin`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async addPreScreenQuestions(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/Survey-Questions`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async updatePreScreenQuestions(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyQuestion`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async getSurveyQuestions(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getSurveyQuestions`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async matchAnswers(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/matchAnswers`, payload)
        .then((res) => {
            return _.get(res, 'data.data',{});
        });
    }

    static async getstatusFinance(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getstatusFinance`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async BothmanualBulkApprove(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/BothmanualBulkApprove`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async allInactiveUsers(
        limit: any,
        pagenumber: number,
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return await api.post(`${userURL}/allInactiveUsers/${limit}/${pagenumber}`, payload)
            .then((res) => {
                return _.get(res, 'data', {});
            });
    }

    static async getAllQuestions(
        data:any
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getAllQuestions`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async SurveyQuestions(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/Survey-Questions`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async updateSurveyQuestions(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyQuestions`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async updateSurveyquestionanswer(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/updateSurveyquestionanswer`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async getAnswersOfQuestion(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/getAnswersOfQuestion`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async deleteQuestion(
        payload: { userAuthId: string; } & any,
    ): Promise<any> {
        return api.post(`${redemptionRespondentRequestURL}/deleteQuestion`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }
    
    static downloadManualRewards(
        id,
    ): Promise<any[]> {
        return api.post(`${rewardsAPIURL}/downloadManualRewards`, {id}).then((res) => {
            return _.get(res, 'data.message', []);
        });
    }
    
    static resendEmailVerifyMail(
        payload,
    ): Promise<any[]> {
        return api.post(`${userURL}/resendEmailVerifyMail`, payload).then((res) => {
            return _.get(res, 'data.message', []);
        });
    }
    
    static deleteBulkUserRequests(
        payload,
    ): Promise<any[]> {
        return api.post(`${userURL}/deletebulkuser`, payload).then((res) => {
            return _.get(res, 'data.message', []);
        });
    }
    
    static getAllDeletedUsers(
        limit: any,
        pagenumber: string,
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return api.post(`${userURL}/getAllDeletedUsers/${limit}/${pagenumber}`, payload).then((res) => {
            return _.get(res, 'data', []);
        });
    }

    static deleteDeletedUsers(
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return api.post(`${userURL}/deleteDeletedUsers`, payload).then((res) => {
            return _.get(res, 'data.message', []);
        });
    }

    static createBanners(
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return api.post(`${userURL}/createBanners`, payload).then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static getbanner(
        // limit: any,
        // currentPageNumber: any,
        payload: { userAuthId: string; } & any,
    ): Promise<any[]> {
        return api.post(`${userURL}/getbanner`, {}).then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static async uploadUserProfilecopy(
        payload: { userAuthId: string; } & any
    ): Promise<any> {
        return api.post(`${userURL}/uploadUserProfilecopy`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async deletebanner(
        payload: { userAuthId: string; } & any
    ): Promise<any> {
        return api.post(`${userURL}/deletebanner`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async bannerverification(
        payload: { userAuthId: string; } & any
    ): Promise<any> {
        return api.post(`${userURL}/bannerverification`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static async bannerverificationfinal(
        payload: { userAuthId: string; } & any
    ): Promise<any> {
        return api.post(`${userURL}/bannerverificationfinal`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

}
