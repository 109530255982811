import React from 'react';
import {
    Field,
    reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';

import { Show } from 'Layout';
import {Operands, PageStatus, VoucherType} from 'enums';
import {RedemptionModeAPI, SecAPI} from "../../API";
import { redeemPointsDict } from 'Languages/MyRequestsTranslations';
import { changePasswordDict } from 'Languages/ContactsTranslations';
import { checkNumberValidation, checkEmailValidation } from "../../Helper/Validations";
import { AuthAPI } from '../../API';
import Language from "../../Languages/Login/content.json";
import { store } from 'reducers';

export type FormValue = {
    "name": string,
};

type State = {
    status: string,
    error: string | null,
    country: any,
    name: string,
};

class Form extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            isActive: 1,
            points: 0,
            modes: [],
            mode: "",
            mobileNumber: "",
            email: "",
            phoneOtp: "",
            mobileOtpSentTrue: false,
            pageHeaderText: redeemPointsDict[this.props.language]["Redeem Points"] || "Redeem Points",
            isNormalOpenForm: true,
            isMobileNotVerified: false,
            isEmailNotVerified: false,
            pageContent: this.props.language === 'hi' ? Language.OTPVerifyHindi : Language.OTPVerifyEnglish,
            oldEmail : '',
            oldPhoneNumber: '',
        };
    }


    componentDidMount() {
            this.fetchModes();
    }

    fetchModes = () => {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => RedemptionModeAPI.redemptionList(10000))
            .then((surveyData: any) => {
                if (!!surveyData) {
                    const options = surveyData.map((item) => ({
                        label: item.name,
                        value: item.name,
                    }));
                    this.setState({
                        modes: options,
                        status: PageStatus.Loaded
                    });
                }
            })
            .catch((err) => {
                this.setState({ error: err.message, status: PageStatus.Error });
            });
    };

    formValues() {
        return {
            pointsRequested: parseInt(this.state.points, 10),
            redemptionModeTitle: this.state.mode,
            redemptionModeId: 'a8a39cc6-d3ab-48e7-aab2-564a267476c7', //Temp
            userId: this.props.userId,
            redemptionRequestStatus: 'New',
            notes: 'NA',
            pointsRedeemed: 0,
            language: this.props.language
        };
    }

    onSubmit() {
        if (!this.props.id) {
            return this.create();
        }
    }

    create() {
        const valuesIn = this.formValues()
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => RedemptionModeAPI.createRedemptionRequest(valuesIn))
            .then((country) => {
                this.props.onSubmit(country.id);
                return this.setState({ status: PageStatus.Submitted });
            })
            .catch((error) => {
                let errMsg = error.message;
                const state = store.getState();
                const email = state.adminUser.adminUser.email;
                const mobileNumber = state.adminUser.adminUser.phoneNumber;
                if(error.message == "Your phone number is not verified, please verify your phone number first"){
                    this.setState({isMobileNotVerified : true, isNormalOpenForm: false, pageHeaderText: redeemPointsDict[this.props.language]["Verify Mobile"] || "Verify Mobile", mobileNumber, oldPhoneNumber: mobileNumber}, ()=>{this.verifyMobileCrendential()});
                    errMsg = redeemPointsDict[this.props.language]["Phone not verified"] || "Phone not verified";
                }
                if(error.message == "Your email is not verified, please verify your email first"){ 
                    this.setState({isEmailNotVerified : true, isNormalOpenForm: false, pageHeaderText: redeemPointsDict[this.props.language]["Verify Email"] || "Verify Email", email, oldEmail: email}); 
                    errMsg = redeemPointsDict[this.props.language]["Email not verified"] || "Email not verified";
                }
                this.setState({ status: PageStatus.Error, error: errMsg });
            });
    }

    reset() {
        return this.setState({
            points: '',
            mode: ''
        });
    }

    isValidPoints() {
        // const remaining = this.props.totalLeft - this.state.points
        // return remaining >= 100
        return true
    }

    handleIpChange = (e, type, validate) => {
        if(checkNumberValidation(e)){ return }
        if (e.target.value.length <= parseInt(validate)) { 
            if(type == "phoneOtp"){
                this.setState({
                    phoneOtp: e.target.value.trim(),
                    error: '',
                });
            }
            if(type == "mobileNumber"){
                this.setState({
                    mobileNumber: e.target.value.trim(),
                    error: '',
                    mobileOtpSentTrue: false,
                });
            }
        }
    };

    verifyEmailFunc = () => {
        if (!checkEmailValidation({"target" :{"value":this.state.email}}) && this.props.userId) {
            let obj = {email: this.state.email, userId: this.props.userId}
            return Promise.resolve()
            .then(() => this.setState({status: PageStatus.Submitting}))
            .then(() => AuthAPI.resendEmailVerifyMail(obj))
            .then((profile) => { this.setState({status: PageStatus.Submitted}); alert(redeemPointsDict[this.props.language]["Check and verify email"] || "Check and verify email"); this.props.onClose()})
            .catch((error) => { this.setState({status: PageStatus.Error, error: (redeemPointsDict[this.props.language][error.message] ?? error.message)}); });
        }
    }

    verifyMobileCrendential = () => {
        if (this.state.mobileNumber && this.props.userId && this.state.mobileNumber.length == 10) {
            let obj = {phoneNumber: this.state.mobileNumber, userId: this.props.userId}
            return Promise.resolve()
            .then(() => this.setState({status: PageStatus.Submitting}))
            .then(() => AuthAPI.resendOtp(obj))
            .then((profile) => { this.setState({status: PageStatus.Submitted, mobileOtpSentTrue: true}); })
            .catch((error) => { this.setState({status: PageStatus.Error, error: error.message}); });
        }
    }

    verifyMobileOtp = () => {
        let obj = { otp: this.state.phoneOtp, userId: this.props.userId }
        return Promise.resolve()
        .then(() => this.setState({status: PageStatus.Submitting}))
        .then(() => AuthAPI.verifyMobileOtp(obj))
        .then((res) => {
            alert(this.state.pageContent.items[2].title);
            this.setState({
                isMobileNotVerified: false, 
                isNormalOpenForm: true, 
                pageHeaderText: redeemPointsDict[this.props.language]["Redeem Points"] || "Redeem Points", 
                status: PageStatus.Submitted,
            })
        })
        .catch((error) => {
            this.setState({status: PageStatus.Error, error: error.message});
        });
    }

    render() {
        const lang = this.props.language ?? 'en';
        return (
            <Modal
            centered
            size="lg"
            backdrop="static"
            onHide={this.props.onClose}
            show={this.props.show}
            style={{ zIndex: 1201 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.state.pageHeaderText}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                    <div className="d-flex justify-content-center w-100 p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </Show>

                <form onSubmit={this.props.handleSubmit((event) => this.onSubmit())}>
                    <Show when={this.state.isNormalOpenForm === true}>
                        <div className="form-group">
                            <label htmlFor="titleEng">
                                {redeemPointsDict[lang]["Points"] || "Points"}*
                            </label>
                            <input
                                className="form-control"
                                onChange={(e) => this.setState({ points: e.target.value })}
                                value={this.state.points}
                                type="number"
                                placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                                required
                            />
                            <label htmlFor="titleEng">
                                {redeemPointsDict[lang]["Description"] || "Description"}*
                            </label>
                            <select
                                name='mode'
                                id='mode'
                                value={this.state.mode}
                                required
                                onChange={(e) => this.setState({ mode: e.target.value })}
                                style={{
                                    width: '100%',
                                    display: 'block',
                                    height: '40px',
                                    lineHeight: '1.5',
                                    color: '#495057',
                                    backgroundColor: '#fff',
                                    backgroundClip: 'padding-box',
                                    border: '1px solid #ced4da',
                                    borderRadius: '5px',
                                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                                }}
                            >
                                <option value=''>{redeemPointsDict[lang]["Choose"] || "Choose"}</option>
                                {this.state.modes.length > 0 ? this.state.modes.map((item) => (
                                    <option key={item.value} value={item.value}>{item.label}</option>
                                )) : ''}
                            </select>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center mt-2">
                            <button
                                type="submit"
                                disabled={!this.state.points || !this.state.mode || !this.isValidPoints()}
                                className="btn btn-primary mr-3"
                            >
                                {redeemPointsDict[lang]["Submit"] || "Submit"}
                            </button>

                            <button
                                type="button"
                                onClick={() => this.reset()}
                                className="btn btn-light mr-3"
                            >
                                {redeemPointsDict[lang]["Reset"] || "Reset"}
                            </button>

                        </div>
                    </Show>
                    <Alert variant="danger" show={!!this.state.error} className="mt-2">
                        {this.state.error}
                    </Alert>
                    <Show when={this.state.status === PageStatus.Submitting}>
                        <Spinner animation="border" variant="primary" />
                    </Show>
                </form>
                <Show when={this.state.isMobileNotVerified === true}>
                    <div>
                        <label htmlFor="mobile">
                            {redeemPointsDict[lang]["Mobile"] || "Mobile"}*
                        </label>
                        <input
                            className="form-control"
                            onChange={(e) => {this.state.oldPhoneNumber ? null : this.handleIpChange(e, "mobileNumber", 10)}}
                            onBlur={()=> {this.state.oldPhoneNumber ? null : this.verifyMobileCrendential()}}
                            value={this.state.mobileNumber ?? ''}
                            type="number"
                            placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                            required
                        />
                        <label htmlFor="phoneOtp">
                            {redeemPointsDict[lang]["OTP"] || "OTP"}*
                        </label>
                        <input
                            className="form-control"
                            onChange={(e) => {this.handleIpChange(e, "phoneOtp", 4)}}
                            value={this.state.phoneOtp}
                            type="number"
                            placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                            disabled={this.state.mobileNumber.length != 10 || this.state.mobileOtpSentTrue == false}
                            required
                        />
                        <hr />
                        <button
                            disabled={this.state.phoneOtp.length != 4 || this.state.mobileNumber.length != 10}
                            className="btn btn-success mr-3"
                            onClick={()=> {this.state.phoneOtp.length == 4 && this.state.mobileNumber.length == 10 ? this.verifyMobileOtp() : null}}
                        >
                            {redeemPointsDict[lang]["Verify"] || "Verify"}
                        </button>
                    </div>
                </Show>
                <Show when={this.state.isEmailNotVerified === true}>
                    <div>
                        <label htmlFor="emailVerify">
                            {redeemPointsDict[lang]["Email"] || "Email"}*
                        </label>
                        <input
                            className="form-control"
                            onChange={(e) => {this.state.oldEmail ? null : this.setState({email: e.target.value, error: '',})}}
                            value={this.state.email ?? ''}
                            type="text"
                            placeholder={changePasswordDict[lang]["Enter..."] || "Enter..."}
                            required
                        />
                        <hr />
                        <button
                            disabled={checkEmailValidation({"target" :{"value":this.state.email}})}
                            className="btn btn-success mr-3"
                            onClick={()=> this.verifyEmailFunc() }
                        >
                            {redeemPointsDict[lang]["Verify"] || "Verify"}
                        </button>
                    </div>
                </Show>
            </Modal.Body>
        </Modal>
        );
    }
}

const FormRedux = reduxForm<FormValue, any>({
    form: 'labelsForm',
})(Form);


const FormWithRouter = withRouter(FormRedux);

export { FormWithRouter as Form };
