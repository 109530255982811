import React, { Component } from 'react';
import { Alert, Button, Spinner, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from './Form';
import { PageStatus } from 'enums';
import { Show } from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import { exportToExcel } from "../../Utils/ExportToExcel";
import { AuthAPI, NewsLetterAPI } from "../../API";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import { Confirmation } from "../../Shared/Confirmation";

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    BODY: 'BODY'
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    body: string | null,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        name: '',
    },
    pageSize: any,
    currentPageNumber: any,
    totalResponseData: any,
    totalResponsePages: any,
    cat: string,
    teampleate: string
};

class MoviesBanner extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            filteredData: [],
            filters: {
                name: '',
                type: '',
            },
            pageSize: 10,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: '',
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList()
    }

    fetchList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.getbanner({}))
            .then((countries) => {
                let responseData: any = countries;
                this.setState({ data: responseData, filteredData: responseData, totalResponseData: responseData.length, totalResponsePages: 1, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    clearFilter = () => {
        this.setState({
            filters: {
                name: '',
                type: '',
            },
        })
        this.fetchList()
    }

    handleExport() {
        exportToExcel(this.state.filteredData, 'banners');
    };

    handlePageSizeChange = (newValue: any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue: any) => { this.setState({ currentPageNumber: newValue }); };

    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };

    onCloseModalFunc = () => {
        this.setState({ showBannerForm: false, name: '', description: '' });
    }

    deleteSweepstakeActions(id): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => AuthAPI.deletebanner({id}))
            .then((countries) => {
                alert("Sweepstake deleted successfully");
                this.fetchList();
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    render() {
        const { filteredData, filters, pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Sweepstakes</h4>
                                <div>
                                    <Button
                                        onClick={() => {
                                            return this.setState({
                                                formType: MODAL_TYPES.CREATE
                                            });
                                        }}
                                        variant="primary"
                                        size="sm"
                                        className="mx-1"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                                        Add Image
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.formType === MODAL_TYPES.CREATE}>
                        <Form
                            show={this.state.formType === MODAL_TYPES.CREATE}
                            userId={this.props.userId}
                            onClose={() => this.setState({
                                formType: MODAL_TYPES.NONE,
                            })}
                            onSubmit={() => {
                                this.setState({
                                    formType: MODAL_TYPES.NONE,
                                });
                                this.fetchList();
                            }}
                        />
                    </Show>
                    <Show when={this.state.status === PageStatus.Loaded}>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <div>
                            <div className='mb-2'>
                                <ShowDataAccording2PageSize
                                    currentPageSize={pageSize}
                                    currentPage={currentPageNumber}
                                    sendPageSize={this.handlePageSizeChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                            <Table responsive size="sm" bordered>
                                <thead>
                                    <tr className='text-center'>
                                        <th>S.No</th>
                                        <th>English Name</th>
                                        <th>Hindi Name</th>
                                        {/* <th>Type</th> */}
                                        <th>English Image</th>
                                        <th>Hindi Image</th>
                                        <th>CreatedAt</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((info, index) => (
                                        <tr key={info.id}>
                                            <td className='text-center'>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                                            <td>
                                                {info.bannerNameEng || 'NA'}
                                            </td>
                                            <td>
                                                {info.bannerNameHi || 'NA'}
                                            </td>
                                            {/* <td>{info.type}</td> */}
                                            <td>
                                                <a
                                                    role="button"
                                                    tabIndex={0}
                                                    className="text-primary"
                                                    href={process.env.REACT_APP_BASE_URL_API + info.bannerImgName}
                                                    target='_blank'
                                                >
                                                    Click to view
                                                </a>
                                            </td>
                                            <td>
                                                <a
                                                    role="button"
                                                    tabIndex={0}
                                                    className="text-primary"
                                                    href={`${process.env.REACT_APP_BASE_URL_API}` + `${info.bannerImgHiName}`}
                                                    target='_blank'
                                                >
                                                    Click to view
                                                </a>
                                            </td>
                                            <td>{moment(info.created_at).format('MM/DD/YYYY HH:mm A')}</td>
                                            <td className='text-center'>
                                                <Confirmation onAction={() => filteredData.length > 1 && this.deleteSweepstakeActions(info.id)} body={filteredData.length > 1 ? "Are you sure want to delete sweepstake image?" : "You cannot delete last sweepstake image."}>
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        className="mx-1"
                                                    >
                                                        Delete
                                                    </Button>
                                                </Confirmation>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div>
                                <PaginationNumbering
                                    currentPage={currentPageNumber}
                                    returnPagenumber={this.handlePageNumberChange}
                                    showTotalData={totalResponseData}
                                    sendLastPage={totalResponsePages}
                                />
                            </div>
                        </div>
                    </Show>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.adminUser.adminUser.userId,
    };
}

const MoviesBannerWithRouter = withRouter(connect(
    mapStateToProps,
)(MoviesBanner));

export { MoviesBannerWithRouter as MoviesBanner };
