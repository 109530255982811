import React, { Component } from 'react';
import { Alert, Button, Spinner, Table, Modal, } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from './Form';
import { Details } from './Details';
import { PageStatus } from 'enums';
import { Show } from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import moment from "moment/moment";
import { PartnersAPI } from "../../API";
import ShowDataAccording2PageSize from 'Components/ShowEntries/ShowEntries';
import PaginationNumbering from 'Components/ShowEntries/Pagination';
import { Confirmation } from 'Shared/Confirmation';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    clients: any[],
    id?: string | null,
    pageSize: any,
    currentPageNumber: any,
    totalResponseData: any,
    totalResponsePages: any,
};

export class ClientsList extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            id: null,
            clients: [],
            pageSize: 10,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: '',
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        this.fetchList();
    }

    fetchList(): Promise<void> {
        const { pageSize, currentPageNumber } = this.state;
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => PartnersAPI.getAllClients(pageSize, currentPageNumber))
            .then((data) => {
                this.setState({ clients: data.data, totalResponseData: data.totalCount, totalResponsePages: data.totalPage, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }

    onCloseModalFunc(): void {
        this.setState({ formType: MODAL_TYPES.NONE });
    }

    deleteClientData(id): Promise<void> {
        return Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => PartnersAPI.deleteClientsDetails(id))
        .then(() => { this.setState({ status: PageStatus.Loaded, }, ()=> {this.fetchList()}); })
        .catch((error) => {
            this.setState({ error: error.message, status: PageStatus.Error });
        });
    }

    handlePageSizeChange = (newValue: any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }); };
    handlePageNumberChange = (newValue: any) => { this.setState({ currentPageNumber: newValue }); };

    componentDidUpdate(prevProps: {}, prevState: State) {
        if (prevState.pageSize !== this.state.pageSize || prevState.currentPageNumber !== this.state.currentPageNumber) { this.fetchList(); }
    };

    render() {
        const { pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <GridContainer>
                    <div className='w-100 mb-3'>
                        <CardHeader color="primary">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Clients</h4>
                                <div>
                                    <Button
                                        onClick={() => {
                                            return this.setState({
                                                formType: MODAL_TYPES.CREATE,
                                            });
                                        }}
                                        variant="primary"
                                        size="sm"
                                        className="mx-1"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                    </div>
                </GridContainer>
                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={this.state.formType === MODAL_TYPES.CREATE || this.state.formType === MODAL_TYPES.UPDATE}>
                            <Form
                                show={this.state.formType === MODAL_TYPES.CREATE || this.state.formType === MODAL_TYPES.UPDATE}
                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                })}
                                onSubmit={(id) => {
                                    this.fetchList();
                                    this.setState({
                                        formType: MODAL_TYPES.DETAILS, id: id,
                                    });
                                }}
                                id={this.state.formType === MODAL_TYPES.UPDATE ? this.state.id : null}
                            />
                        </Show>

                        <Show when={!this.state.clients.length}>
                            <Alert variant="info" show={!this.state.clients.length}>
                                No data is available
                            </Alert>
                        </Show>

                        <Show when={!!this.state.clients.length}>
                            <Show when={this.isShowDetailModal()}>
                                <Details
                                    id={this.state.id}
                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onUpdate={() => {
                                        this.setState({
                                            formType: MODAL_TYPES.UPDATE,
                                        });
                                    }}
                                    onDelete={(id) => {
                                        this.setState({
                                            formType: MODAL_TYPES.DELETE,
                                            id: id,
                                        });
                                    }}
                                />
                            </Show>
                            <div>
                                <div className='mb-2'>
                                    <ShowDataAccording2PageSize
                                        currentPageSize={pageSize}
                                        currentPage={currentPageNumber}
                                        sendPageSize={this.handlePageSizeChange}
                                        showTotalData={totalResponseData}
                                        sendLastPage={totalResponsePages}
                                    />
                                </div>
                                <Table responsive size="sm" bordered>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Company Name</th>
                                            <th>Contact Name</th>
                                            <th>Number</th>
                                            <th>Email</th>
                                            <th>State</th>
                                            <th>City</th>
                                            <th>Zip Code</th>
                                            <th>Country</th>
                                            <th>CreatedAt</th>
                                            <th>UpdatedAt</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.clients.map((data, index) => (
                                                <tr key={data.id}>
                                                    <td>{((pageSize) * (currentPageNumber - 1)) + (index + 1)}</td>
                                                    <td>
                                                        <span
                                                            aria-label="button"
                                                            role="button"
                                                            tabIndex={0}
                                                            className="text-primary"
                                                            onKeyPress={() => null}
                                                            onClick={() => {
                                                                this.setState({
                                                                    formType: MODAL_TYPES.DETAILS,
                                                                    id: data.id,
                                                                });
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.name || 'Title',
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{data.contactName}</td>
                                                    <td>{data.number}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.state}</td>
                                                    <td>{data.city}</td>
                                                    <td>{data.zipCode}</td>
                                                    <td>{data.country}</td>
                                                    <td>{moment(data.createdAt).format('MM/DD/YYYY HH:mm A')}</td>
                                                    <td>{moment(data.updatedAt).format('MM/DD/YYYY HH:mm A')}</td>
                                                    <td>
                                                        <Confirmation onAction={() => this.deleteClientData(data.id)} body="Are you sure want to delete this client?">
                                                            <button className='btn btn-danger btn-sm' >Delete</button>
                                                        </Confirmation>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <div>
                                    <PaginationNumbering
                                        currentPage={currentPageNumber}
                                        returnPagenumber={this.handlePageNumberChange}
                                        showTotalData={totalResponseData}
                                        sendLastPage={totalResponsePages}
                                    />
                                </div>
                            </div>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}
