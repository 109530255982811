import React from 'react';
import {
    Field,
    reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { AuthAPI, NewsLetterAPI, SamplesAPI } from "../../API";
import { checkNumberValidation } from "../../Helper/Validations";

export type FormValue = {
    bannerNameEng: string,
    bannerNameHi: string,
    type: string,
    bannerImgName: string,
    bannerImgHiName: string,
    otp: string,
};

type State = {
    status: string,
    error: string | null,
    bannerNameEng: string,
    bannerNameHi: string,
    type: string,
    bannerImgName: string,
    bannerImgHiName: string,
    otp: string,
};

class Form extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            bannerNameEng: "",
            bannerNameHi: "",
            type: "desktop",
            bannerImgName: "",
            bannerImgHiName: "",
            submitBtnEngState: true,
            submitBtnHiState: true,
            otp: '',
            phoneNumber: '',
            email: '',
            showSweepStakeModal: false,
            showOtpVerifyModal: this.props.show,
        };
    }

    formValues() {
        return {
            bannerNameEng: this.state.bannerNameEng,
            bannerNameHi: this.state.bannerNameHi,
            type: this.state.type,
            bannerImgName: this.state.bannerImgName,
            bannerImgHiName: this.state.bannerImgHiName,
        };
    }

    initializeValues(data) {
        return this.setState({
            bannerNameEng: data.bannerNameEng,
            bannerNameHi: data.bannerNameHi,
            type: data.type,
            // bannerImgName: data.bannerImgName,
        });
    }

    onSubmit() {
        return this.create();
    }

    create() {
        const valuesIn = this.formValues();
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => AuthAPI.createBanners(valuesIn))
            .then((data) => {
                alert("Sweepstake image added successfully.");
                this.props.onSubmit();
                return this.setState({ status: PageStatus.Submitted });
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    handleBannerUpload(e, type) {
        try {
            this.setState({ status: PageStatus.Loading });
            const file = e.target.files[0];
            if (file) {
                const newDate = Date.now();
                const newName = file.name;
                const formData = new FormData();
                formData.append('image', file);
                formData.append('bannerName', newDate + '_' + newName.replaceAll(" ", "").split('.')[0]);
                formData.append("banneraccestoken", "ZXlKaGJHY2lPaUpTVXpJMU5pSjkuZXlKaGJHY2lPaUpTVXpJMU5pSjkuZXlKaGJHY2lPaUpTVXpJMU5pSjku");
                const url3 = process.env.REACT_APP_BASE_URL_API + '/api/v1/auth/user/uploadUserProfilecopy';
                fetch(url3, {
                    method: 'POST',
                    body: formData
                })
                .then(async(res) => {return await  res.json() })
                .then((respo) => {
                    if(type == "Eng"){ this.setState({ bannerImgName: respo.data, submitBtnEngState: false, status: PageStatus.None }) }
                    if(type == "Hi"){ this.setState({ bannerImgHiName: respo.data, submitBtnHiState: false, status: PageStatus.None }) }
                })
                .catch((err) => {
                    console.error(err);
                }); 
            }
        } catch (error) {
            this.setState({ error: error.message, status: PageStatus.None })
        }
    }

    handleOtpValidation = (e) => {
        if(checkNumberValidation(e)){ return }
        if (e.target.value.length <= 4) { 
            this.setState({
                otp: e.target.value.trim(),
            });
        }
    };

    resendOtp() {
        return Promise.resolve()
        .then(() => null)
        .then(() => AuthAPI.bannerverification({phoneNumber: this.state.phoneNumber, language: "en"}))
        .then((data) => {
            this.setState({status: PageStatus.None, error: null,})
            return true;
        })
        .catch((error) => {
            this.setState({ status: PageStatus.Error, error: error.message });
        });
    }

    otpVerificationFunc() {
        return Promise.resolve()
        .then(() => null)
        .then(() => AuthAPI.bannerverificationfinal({phoneNumber: this.state.phoneNumber, language: this.props.language, otp: this.state.otp,}))
        .then((data) => {
            this.setState({showSweepStakeModal: true, showOtpVerifyModal: false, status: PageStatus.None, error: null,})
        })
        .catch((error) => {
            this.setState({ status: PageStatus.Error, error: error.message });
        });
    }



    async loadProfile () {
        Promise.resolve()
            .then(() => {
            if (!this.props.userId) {
                return Promise.reject(new Error("Invalid ID"));
            }
            return AuthAPI.profile(this.props.userId);
            })
            .then((user) => {
            if (!!user) {
                this.setState({email : user.dataValues.email, phoneNumber: user.dataValues.phoneNumber}, ()=>{this.resendOtp()});
            }
            })
            .catch((error) => {
            alert(error);
            });
    };

    componentDidMount() {
        this.loadProfile();
    }

    reset() {
        return this.setState({
            bannerNameEng: '',
            bannerNameHi: '',
            type: 'desktop',
            bannerImgName: '',
            bannerImgHiName: '',
            submitBtnEngState: true,
            submitBtnHiState: true,
            otp: '',
        });
    }

    render() {
        return (
            <>
                <Modal
                    centered
                    size="lg"
                    backdrop="static"
                    onHide={this.props.onClose}
                    show={this.state.showSweepStakeModal}
                    style={{ zIndex: 1201 }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {!!this.props.id ? "Update " : "Add "} Image
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>

                        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                            {this.state.error}
                        </Alert>

                        <form onSubmit={this.props.handleSubmit(
                            (event) => this.onSubmit(),
                        )}
                        >
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="bannerNameEng">Sweepstake English Name*</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={this.state.bannerNameEng}
                                        onChange={(e) => { this.setState({ bannerNameEng: e.target.value }) }}
                                        id="bannerNameEng"
                                        placeholder="Enter here"
                                        required
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="bannerNameHi">Sweepstake Hindi Name*</label>
                                    <input
                                        type="text"
                                        className='form-control'
                                        value={this.state.bannerNameHi}
                                        onChange={(e) => { this.setState({ bannerNameHi: e.target.value }) }}
                                        id="bannerNameHi"
                                        placeholder="Enter here"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <label htmlFor="bannerImgName">English Image*</label>
                                    <input
                                        className="form-control"
                                        name="bannerImgName"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => this.handleBannerUpload(e, "Eng")}
                                        placeholder="Enter here"
                                        required={!!this.props.id ? false : true}
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="bannerImgHiName">Hindi Image*</label>
                                    <input
                                        className="form-control"
                                        name="bannerImgHiName"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => this.handleBannerUpload(e, "Hi")}
                                        placeholder="Enter here"
                                        required={!!this.props.id ? false : true}
                                    />
                                </div>
                            </div>

                            <hr />
                            <div className="d-flex align-items-center mt-2">
                                <Show when={this.state.status === PageStatus.Loading}>
                                    <div className="mr-3">
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                </Show>
                                <Show when={this.state.status === PageStatus.None}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary mr-3"
                                        disabled={this.state.submitBtnEngState == true || this.state.submitBtnHiState == true || this.state.otp.length < 4}
                                    >
                                        Submit
                                    </button>
                                </Show>
                                <button
                                    type='button'
                                    onClick={() => this.reset()}
                                    className="btn btn-light mr-3"
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    onHide={this.props.onClose}
                    show={this.state.showOtpVerifyModal}
                    backdrop="static"
                    centered
                    size="md"
                    style={{ zIndex: 1201, background: 'rgba(0, 0, 0, 0.3)' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            OTP Verification
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>
                        <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                            {this.state.error}
                        </Alert>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="otp">OTP*</label>
                                <input
                                    className="form-control"
                                    name="otp"
                                    value={this.state.otp}
                                    type="text"
                                    onChange={(e) => this.handleOtpValidation(e)}
                                    placeholder="Enter here"
                                    required={true}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type='button'
                            onClick={() => this.resendOtp()}
                            className="btn btn-success mr-3"
                        >
                            Resend OTP
                        </button>
                        <button className="btn btn-primary" onClick={() => this.otpVerificationFunc()}>
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const dataFormRedux = reduxForm<any, any>({
    form: 'dataForm',
})(Form);

const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
