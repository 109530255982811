import React from 'react';
import {
    Field,
    reduxForm,
} from 'redux-form';
import {Alert, Button, Modal, Spinner} from 'react-bootstrap';
import { withRouter } from 'react-router';
import csvtojson from 'csvtojson';
import { Show } from 'Layout';
import {Operands, PageStatus, VoucherType} from 'enums';
import {AuthAPI, RedemptionModeAPI, SecAPI} from "../../API";
import { referralModalDict } from 'Languages/ReferralTranslations';
import { checkNumberValidation, checkEmailValidation } from "../../Helper/Validations";
import { WhatsApp } from "@material-ui/icons";

export type FormValue = {
    "name": string,
};


type State = {
    status: string,
    error: string | null,
    country: any,
    name: string,
};

class Form extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            isActive: 1,
            points: 0,
            mode: "",
            importedData: { users: [] },
            name: "",
            email: "",
            referralStatus: "Invited",
            referralMethod: "Manual",
            phoneNumber: "",
            remainingReferrals: 0,
        };
    }


    formValues() {
        return {
            name: this.state.name,
            email: this.state.email,
            userId: this.props.userId,
            referralStatus: "Invited",
            referralMethod: "Manual",
            phoneNumber: this.state.phoneNumber
        };
    }

    checkPhoneNumberValidation(e) {
        if(checkNumberValidation(e)){ return }
        if (e.target.value.length <= 10 ) { this.setState({ phoneNumber: e.target.value });  }
    }
    
    checkRemainingReferrals() {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => AuthAPI.getRemainingReferalCount({userId: this.props.userId}))
            .then((response) => {
                this.setState({remainingReferrals: response});
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    create() {
        if(this.state.remainingReferrals == 0){
            alert(referralModalDict[this.props.language]["No more referrals"] || "You have exceeded the limit of referrals within 30 days.");
            return;
        }
        if(1 > this.state.remainingReferrals){
            alert(
                this.props.language == "en" ? `Please ensure to keep ${this.state.remainingReferrals} referrals only in attachment for this month.` : 
                `कृपया सुनिश्चित करें कि इस महीने केवल ${this.state.remainingReferrals} रेफर को ही अटैचमेंट में रखें।`
            );
            return;
        }
        const valuesIn = this.formValues()
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => AuthAPI.createReferralRequest(valuesIn, this.props.language))
            .then((country) => {
                this.props.onSubmit(this.props.userId);
                this.checkRemainingReferrals();
                return this.setState({ status: PageStatus.Submitted });
            })
            .catch((error) => {
                let newError = error.message == "You have already send invite to this email" ? referralModalDict[this.props.language]["Already email sent"] || "You have already send invite to this email" : error.message;
                this.setState({ status: PageStatus.Error, error: newError });
            });
    }


    createBulk() {
        if(this.state.remainingReferrals == 0){
            alert(referralModalDict[this.props.language]["No more referrals"] || "You have exceeded the limit of referrals within 30 days.");
            return;
        }
        if(this.state.importedData.users.length > this.state.remainingReferrals){
            alert(
                this.props.language == "en" ? `Please ensure to keep ${this.state.remainingReferrals} referrals only in attachment for this month.` : 
                `कृपया सुनिश्चित करें कि इस महीने केवल ${this.state.remainingReferrals} रेफरल को ही अटैचमेंट में रखें।`
            );
            return;
        }
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Submitting }))
            .then(() => AuthAPI.createBulkReferralRequest(this.state.importedData, this.props.language))
            .then((country) => {
                this.props.onSubmit(this.props.userId);
                this.checkRemainingReferrals();
                return this.setState({ status: PageStatus.Submitted });
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    reset() {
        return this.setState({
            points: '',
            mode: ''
        });
    }

    isValidPoints() {
        const remaining = this.props.totalLeft - this.state.points
        return remaining >= 100
    }

    downloadFile = () => {
        const downloadUrl = `https://indiapolls.com:9000/Images/Sample.csv`;
        window.open(downloadUrl, '_blank');
    };

    handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const jsonData = await this.convertCsvToJson(file);
                let transformedData = jsonData.map(this.modifiedConvertedJson);
                for(let i=0; i<transformedData.length; i++){
                    if(transformedData[i].phoneNumber == null || transformedData[i].phoneNumber == undefined || transformedData[i].phoneNumber.length!=10){
                        alert(this.props.language == "en" ? `Mobile number is not correct in row ${i+2}` : `पंक्ति ${i+2} में, मोबाइल नंबर सही नहीं है।`);
                        transformedData = [];
                        break;
                    }
                    if(transformedData[i].name == null || transformedData[i].name == undefined || transformedData[i].name == ""){
                        alert(this.props.language == "en" ? `Name is not correct in row ${i+2}` : `पंक्ति ${i+2} में, नाम सही नहीं है।`);
                        transformedData = [];
                        break;
                    }
                    if(transformedData[i].email == null || transformedData[i].email == undefined || transformedData[i].email == ""){
                        alert(this.props.language == "en" ? `Email is not correct in row ${i+2}` : `पंक्ति ${i+2} में, ईमेल सही नहीं है।`);
                        transformedData = [];
                        break;
                    }
                }
                this.setState({ importedData: { users: transformedData } });
                // console.log(transformedData);
            } catch (error) {
                console.error('Error converting CSV to JSON:', error);
            }
        }else{
            this.setState({ importedData: { users: [] } });
        }
    };

    convertCsvToJson = (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const csvContent: any = reader.result

                if (!csvContent.trim()) {
                    reject(new Error('CSV content is empty.'));
                    return;
                }

                csvtojson()
                    .fromString(csvContent)
                    .then(jsonData => resolve(jsonData))
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    modifiedConvertedJson = (obj) => {
            return {
                name: obj.Name,
                email: obj.Email,
                phoneNumber: obj.Mobile,
                userId: this.props.userId,
                referralStatus: "Invited",
                referralMethod: "File",
            };
    }

    componentDidMount(): void { this.checkRemainingReferrals(); }

    sendLinkToWhatsapp = ()=>{
        if(this.state.remainingReferrals == 0){
            alert(referralModalDict[this.props.language]["No more referrals"] || "You have exceeded the limit of referrals within 30 days.");
            return;
        }
        if(1 > this.state.remainingReferrals){
            alert(
                this.props.language == "en" ? `Please ensure to keep ${this.state.remainingReferrals} referrals only in attachment for this month.` : 
                `कृपया सुनिश्चित करें कि इस महीने केवल ${this.state.remainingReferrals} रेफरल को ही अटैचमेंट में रखें।`
            );
            return;
        }
        const referralMsg = this.props.language == "en" ? `Hello ${this.state.name}! 👋

I hope you're doing well! I want to share my referral link with you for a service called IndiaPolls. Like me, you can also earn rewards by filling out surveys on interesting topics! 📝💰

Here is my referral link - https://panel.indiapolls.com/#/referrals/view/${this.props.userId}` : `नमस्ते ${this.state.name}! 👋

मुझे आशा है कि आप अच्छे हैं! मैं आपके साथ एक सेवा, जिसका नाम इंडियापोल्स है, के लिए अपना रेफरल लिंक साझा करना चाहता हूँ। आप भी दिलचस्प विषयों पर सर्वे भरकर मेरे समान पुरस्कार कमा सकते हैं! 📝💰

यहाँ मेरा रेफरल लिंक है - https://panel.indiapolls.com/#/referrals/view/${this.props.userId}`;
        const whatsappLink = 'https://wa.me/' + `91${this.state.phoneNumber}` + '?text=' + `${encodeURIComponent(referralMsg)}`;
        window.open(whatsappLink, '_blank');
    }

    render() {
        const lang = this.props.language ?? 'en';
        return (
            <Modal
            centered
            size="xl"
            backdrop="static"
            onHide={this.props.onClose}
            show={this.props.show}
            style={{ zIndex: 1201 }}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {referralModalDict[lang]["Referral"] || "Referral"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
                <Show when={this.state.status === PageStatus.Loading}>
                    <div className="d-flex justify-content-center w-100 p-5">
                        <Spinner animation="border" variant="primary" />
                    </div>
                </Show>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div className='d-flex justify-content-between'>
                        <div><b>{referralModalDict[lang]["Refer a friend"] || "Refer a friend"}</b></div>
                        <div><b>{referralModalDict[lang]["Remaining Referrals"] || "Remaining Referrals:"} </b> <b className={this.state.remainingReferrals < 4 ? "text-danger" : this.state.remainingReferrals < 6 ? "text-warning" : "text-success"}>{this.state.remainingReferrals}</b></div>
                    </div>
                    <hr />
                    <br />
                    <p>
                    {referralModalDict[lang]["Referral Link Description"] || "Referral Link Description"} <a href={`https://panel.indiapolls.com/#/referrals/view/${this.props.userId}`}>
                        <b>{`https://panel.indiapolls.com/#/referrals/view/${this.props.userId}`}</b>
                        </a>
                    </p>
                    <br />
                    <div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="title">{referralModalDict[lang]["Name"] || "Name"}*</label>
                                <input
                                    className="form-control"
                                    name="name"
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    value={this.state.name}
                                    placeholder={referralModalDict[lang]["Enter here"] || "Enter here"}
                                    title={referralModalDict[lang]["Enter here"] || "Enter here"}
                                    required
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="email">{referralModalDict[lang]["Email"] || "Email"}*</label>
                                <input
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    value={this.state.email}
                                    title={referralModalDict[lang]["Enter here"] || "Enter here"}
                                    placeholder={referralModalDict[lang]["Enter here"] || "Enter here"}
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="Mobile">{referralModalDict[lang]["Mobile"] || "Mobile"}*</label>
                                <input
                                    type='text'
                                    className="form-control"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    onChange={(e) => this.checkPhoneNumberValidation(e, 'sendMessage')}
                                    value={this.state.phoneNumber}
                                    title={referralModalDict[lang]["Enter here"] || "Enter here"}
                                    placeholder={referralModalDict[lang]["Enter here"] || "Enter here"}
                                />
                            </div>
                        </div>
                        <div className='d-flex flex-row align-items-center mt-3'>
                            <Button
                                onClick={() => this.create()}
                                variant="primary"
                                disabled={!this.state.name || !this.state.email || checkEmailValidation({"target" :{"value":this.state.email}})}
                                className=""
                                size="sm"
                            >
                                {referralModalDict[lang]["Refer via email"] || "Refer via email"}
                            </Button>
                            <Button 
                                variant='success'
                                className="d-flex align-items-center justify-content-start ml-2" 
                                disabled={this.state.phoneNumber.length !== 10 || this.state.name == ""}
                                onClick={()=> {this.state.phoneNumber.length !== 10 || this.state.name == "" ? null : this.sendLinkToWhatsapp()}}
                                size='sm'
                            >
                                <div>
                                    <WhatsApp className={`text-white mr-1 ${lang == "en" ? " d-none" : ""}`}/>
                                </div>
                                <div className=''>
                                {/* {lang == "en" ? "Refer via " : "लिंक साझा करें"} */}
                                {referralModalDict[lang]["Refer via whatsapp"] || "Refer via "}
                                </div>
                                <div>
                                    <WhatsApp className={`text-white ml-1 ${lang == "en" ? "" : " d-none"}`}/>
                                </div>
                            </Button>
                        </div>
                        <div className='mt-3'>
                            <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                                {this.state.error}
                            </Alert>
                        </div>
                    </div>
                </div>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <div><b>{referralModalDict[lang]["Upload From a CSV list"] || "Upload From a CSV list"}</b></div>
                    <hr />
                    <br />
                    <div>
                        {referralModalDict[lang]["CSV Description"] || "CSV Description"}
                        <a onClick={this.downloadFile} className="link-display" style={{color: 'orange', cursor: 'pointer'}}> {referralModalDict[lang]["Download"] || "Download"} </a>
                        <div>
                            <input className="mt-1" type="file" accept=".csv" 
                                onChange={this.state.remainingReferrals > 0 ? (e) => this.handleFileChange(e) : null} 
                                disabled={this.state.remainingReferrals < 1}
                            />
                        </div>
                        <div>
                            <Button
                                onClick={() => this.createBulk()}
                                variant="primary"
                                disabled={this.state.importedData.users.length === 0}
                                className="mt-3"
                                size="sm"
                            >
                                {referralModalDict[lang]["Import"] || "Import"}
                            </Button>
                        </div>
                    </div>
                </div> 
            </Modal.Body>
        </Modal>

        );
    }
}

export { Form as Form };
