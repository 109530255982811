import React from 'react';
import { withRouter } from 'react-router';
import { Alert, Modal, Spinner, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { PageStatus } from 'enums';
import { Show } from 'Layout';
import { PartnersAPI, SurveysAPI, AuthAPI, MasterDataAPI } from "../../API";
import Select from 'react-select';
import { DashboardTemplates } from "./DashboardTemplates";
import { SurveyEmailSchedule } from "./SurveyEmailSchedule";
import moment from "moment";
import UsersPaginations from "./UsersPaginations";
import { exportToExcel, exportToCSV } from "../../Utils/ExportToExcel";
import { ManualSurveyRewards } from "./ManualSurveyRewards";
import ShowDataAccording2PageSize from '../ShowEntries/ShowEntries';
import PaginationNumbering from '../ShowEntries/Pagination';
import { baseApiURL, surveysURL } from "../../Utils/urls";
import { Form } from './dashboardpartners'; 
import { Confirmation } from 'Shared/Confirmation';
import csvtojson from "csvtojson";


type State = {
    survey: any | null,
    status: any,
    error: string | null,
    pageSize: any,
    currentPageNumber: any,
    loading: any,
};

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    BODY: 'BODY'
};

const calculateTime = (createdAt, updatedAt) => {
    const created = new Date(createdAt);
    const updated = new Date(updatedAt);
    // @ts-ignore
    const timeDifferenceInMilliseconds = updated - created;
    return timeDifferenceInMilliseconds / (1000 * 60);
}

function calculateAge(dateOfBirth) {
    const birthDate = new Date(dateOfBirth);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
    ) {
        return age - 1;
    }

    return age;
}

function getNameById(id, partners) {
    const survey = partners.find(survey => survey.value === id);
    return survey ? survey.label : 'NA';
}

function appendParamsToUrl(baseUrl, partnerId, surveyId) {
    if (baseUrl && partnerId) {
        try {
            const url = new URL(baseUrl);
            url.searchParams.append('partnerid', partnerId);
            // url.searchParams.append('surveyid', surveyId);
            // url.searchParams.append('rid', '');
            return url.toString();
        } catch (error) {
            console.error('Invalid URL:', error.message);
            return baseUrl;
        }
    }
    console.error('Missing baseUrl or partnerId');
    return baseUrl;
}

class DashboardDetails extends React.Component<any, any> {
    static defaultProps = {
        languageId: null,
        hideMenu: false,
        onUpdate: () => null,
    };

    constructor(props) {
        super(props);
        this.state = {
            survey: null,
            status: PageStatus.None,
            error: null,
            partners: [],
            users: [],
            samples: [],
            partnerUsers: [],
            partnersSelected: null,
            selectedPartnerOption: null,
            checkboxChecked: false,
            showManual: false,
            selectedSample: '',
            partnerDetails: [],
            pageSize: 100,
            currentPageNumber: 1,
            totalResponseData: '',
            totalResponsePages: '',
            loading: false,
            name: '',
            showCloneModal: false,
            cloneTypeValue: '',
            surveyIdValue: this.props.id,
            projectId: "",
            projectName: "",
            groupCloneVar: "N",
            showTestUniqueLinkModal: false,
            bulkLinks: [],
            showUploadLinkModal: false,
            bulkUploadLinkData: [],
            selectedPartner4TestLink: '',
            sendLinksTime: '',
            countries: [],
            selectedMoreCountryOption: [],
            newCountries: [],
            olderCountries: [],
        };
    }

    componentDidMount() {
        if (!!this.state.surveyIdValue) {
            this.fetchSurvey();
        }
    }

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            checkboxChecked: !prevState.checkboxChecked,
        }));
    };
    fetchSurvey() {
        const { pageSize, currentPageNumber } = this.state;
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return SurveysAPI.getOneDetails(this.state.surveyIdValue, pageSize, currentPageNumber);
            })
            .then((response: any) => {
                if (!!response.data) {
                    const survey: any = response.data;
                    this.setState({ survey: survey.data, samples: survey.samples, users: survey.users, totalResponsePages: response.totalPage, totalResponseData: response.totalCount, status: PageStatus.Loaded, groupCloneVar: survey.groupClone }, () => {
                        this.fetchPartners()
                    });
                }
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    getpantnerdetailsmain() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return SurveysAPI.getpantnerdetailsmain(this.state.surveyIdValue);
            })
            .then((partnerDetails: any) => {
                if (!!partnerDetails) { this.setState({ partnerDetails, status: PageStatus.Loaded }) }
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    fetchPartners() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return PartnersAPI.getPartners(10000);
            })
            .then((survey) => {
                const options = survey.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));

                if (this.state.survey && this.state.survey.surveypartners.length > 0) {
                    this.getpantnerdetailsmain();
                    const partnerIds = this.state.survey.surveypartners.map((partner) => partner.partnerId);
                    const filteredOptions = options.filter((item) => partnerIds.includes(item.value));
                    partnerIds.forEach((partnerId) => {
                        this.fetchPartnerUsers(partnerId, this.state.surveyIdValue);
                    });
                    this.setState({ selectedPartnerOption: filteredOptions, checkboxChecked: this.state.survey.surveypartners[0].includesid ? this.state.survey.surveypartners[0].includesid : false });
                }
                this.setState({ partners: options, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }


    fetchPartnerUsers(partnerId, surveyId) {
        try {
            if (partnerId && surveyId) {
                Promise.resolve()
                    .then(() => SurveysAPI.partnerSurveyUsers(partnerId, surveyId))
                    .then((response) => {
                        if (response.length > 0) {
                            const transformedData = response.map(item => ({
                                "Survey ID of the Project": this.state.surveyIdValue,
                                "Country": item.country,
                                "India Polls respondent ID": item.id,
                                "Vendor Respondent ID": item.rid,
                                "Status": item.status,
                                "Start IP": item.ip,
                                "End IP": item.ip,
                                "Start Time": item.createdAt,
                                "End Time": item.updatedAt,
                                "LOI (Minutes)": calculateTime(item.createdAt, item.updatedAt),
                                "Survey Name": item.surveyName,
                                "Partner Name": item.partnerName
                            }));
                            if (transformedData.length > 0) {
                                // @ts-ignore
                                this.state.partnerUsers = this.state.partnerUsers.concat(transformedData);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('ERROR: Error In exporting')
                    });
            }
        } catch (error) {
            console.log('ERROR: Error In exporting')
        } finally {
            console.log('ERROR: Error In exporting')
        }
    };


    addPartners() {
        const values = this.formValues();
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return SurveysAPI.addPartners({ partners: values });
            })
            .then((country) => {
                this.setState({ status: PageStatus.Loaded });
                return this.fetchSurvey()

            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    }

    formValues() {
        if (this.state.selectedPartnerOption) {
            return this.state.selectedPartnerOption.map((item) => ({
                surveyId: this.state.surveyIdValue,
                partnerId: item.value,
                includesid: this.state.checkboxChecked
            }))
        }
    }

    handlePartnersChange = async (selectedPartnerOption: { value: string }[]) => {
        if (!Array.isArray(selectedPartnerOption)) {
            console.error('Expected selectedPartnerOption to be an array');
            return;
        }

        const previouslySelected = this.state.selectedPartnerOption || [];
        const previouslySelectedValues = previouslySelected.map(option => option.value);
        const newSelectedValues = selectedPartnerOption.map(option => option.value);

        // Determine which partners have been removed
        const removedPartners = previouslySelectedValues.filter(
            value => !newSelectedValues.includes(value)
        );

        // Update state with selected partners
        this.setState({
            partnersSelected: newSelectedValues,
            selectedPartnerOption
        }, async () => {
            // Handle addition of new partners
            for (const partnerId of newSelectedValues) {
                if (!previouslySelectedValues.includes(partnerId)) {
                    await this.handleCreateClick(partnerId);
                }
            }

            // Handle removal of partners
            for (const partnerId of removedPartners) {
                await this.deletepartnerurl(partnerId);
            }
        });
    };
    // Updated deletepartnerurl function to call the delete API
    deletepartnerurl = (partnerId) => {
        PartnersAPI.deletepartnerurl(partnerId, this.state.surveyIdValue)
            .then(() => {
                // Remove the partner from selectedPartnerOption state
                this.setState((prevState) => ({
                    selectedPartnerOption: prevState.selectedPartnerOption.filter(
                        (partner) => partner.value !== partnerId
                    ),
                }), () => {
                    // Call getpantnerdetailsmain after state has been updated
                    this.getpantnerdetailsmain();
                });
            })
            .catch((error) => {
                console.error('Error deleting partner:', error.message);
            });
    };

    handleCreateClick = async (partnerId: string) => {
        try {
            // const partner = await PartnersAPI.getOne(partnerId);
            const partner = await PartnersAPI.getUrlOnTheSurveyBasis(partnerId, this.state.surveyIdValue);
            this.setState({
                partnerId: partnerId,
                partnerName: partner.name || '', // Set the partnerName here
                showModal: true
            });
        } catch (error) {
            console.error('Failed to fetch partner details:', error);
        }
    };



    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    handleSubmit = (responseId: string) => {
        // Handle the form submission response
        this.handleCloseModal(); // Close the modal after successful submission
    };
    // ==================================================================================

    handleExport() {
        const link = document.createElement('a');
        link.href = `${baseApiURL}${surveysURL}/getSurveyDetailsExport/${this.state.surveyIdValue}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
    };


    handlePartnerExport() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return SurveysAPI.getAllPartnerUsersexport(this.state.surveyIdValue);
            })
            .then((exportData: any) => {
                if (exportData.length > 0) {
                    this.setState({ status: PageStatus.Loaded });
                    exportToExcel(exportData, `${this.props.unique_id} & Partner`);
                } else {
                    alert("No Data Present To Export.");
                    this.setState({ status: PageStatus.Loaded });
                }
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    };

    downloadManualRewards() {
        Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {
                if (!this.state.surveyIdValue) {
                    return Promise.reject(new Error('Invalid ID'));
                }
                return AuthAPI.downloadManualRewards(this.state.surveyIdValue);
            })
            .then((exportData: any) => {
                if (Array.isArray(exportData) && exportData.length > 0) {
                    this.setState({ status: PageStatus.Loaded });
                    exportToExcel(exportData, `Rewards of survey (${this.state.survey?.uniqueid ? this.state.survey?.uniqueid : "Demo"})`);
                } else {
                    alert("Rewards Not Allotted.");
                    this.setState({ status: PageStatus.Loaded });
                }
            })
            .catch((error) => {
                this.setState({ status: PageStatus.Error, error: error.message });
            });
    };

    openModalFor(type){
        this.setState({showCloneModal: true, name: '', cloneTypeValue: type}, ()=> this.fetchOlderCountries());
    }

    cloneThisSurvey() {
        if(this.state.name && this.state.projectName && this.state.projectId && this.state.newCountries.length > 0){
            let payload = {projectName: this.state.projectName, projectId: this.state.projectId, id: this.state.surveyIdValue, name: this.state.name, newCountries: this.state.newCountries, cloneType: this.state.cloneTypeValue == "Group" ? "group" : "individual"};
            Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => {  return SurveysAPI.duplicatesurvey(payload); })
            .then((respoData: any) => {this.setState({ status: PageStatus.Loaded, name: '', showCloneModal: false, cloneTypeValue: '', surveyIdValue: respoData.Survey.id, newCountries: [], selectedMoreCountryOption: [], olderCountries: [],},()=>{this.fetchSurvey();}); this.props.onUpdate("clone", respoData.Survey.id); alert("Survey cloned successfully.")})
            .catch((error) => { this.setState({ status: PageStatus.Error, error: error.message }); });
        }else{
            alert("All fields are required.");
        }
    }

    testCurrentSurvey(){
        Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => {  return SurveysAPI.testSurvey({ surveyId: this.state.surveyIdValue }); })
        .then((respoData: any) => { this.setState({ status: PageStatus.Loaded }); window.open(respoData.testUrl, '_blank'); })
        .catch((error) => { this.setState({ status: PageStatus.Error, error: error.message }); });
    }

    copyTestCurrentSurveyLink(){
        Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => {  return SurveysAPI.testSurvey({ surveyId: this.state.surveyIdValue }); })
        .then((respoData: any) => { this.setState({ status: PageStatus.Loaded }); navigator.clipboard.writeText(respoData.testUrl); alert("Test Url copied to clipboard."); })
        .catch((error) => { this.setState({ status: PageStatus.Error, error: error.message }); });
    }

    showTestUniqueLinksModal() {
        this.setState({showTestUniqueLinkModal: true})
    }

    downloadSampleLinks(type) {
        Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => {
            let obj = type == 'sample' ? [{ link: "" }] : this.state.sendLinksTime == "firstTimeSend" ? [{ link: '', dxUserId: '' }] : [{ link: '', userId: '', dxUserId: '' }];
            exportToCSV(obj, (type == 'sample' ? 'sample of test unique links' : this.state.sendLinksTime == "firstTimeSend" ? "upload partner test link sample" : "resend partner test link sample"));
            this.setState({ status: PageStatus.Loaded });
        })
        .catch((error) => {
            this.setState({ status: PageStatus.Error, error: error.message });
        });
    }

    submitTestUniqueLinksFile() {
        if(this.state.bulkLinks.length < 1){
            alert("Check file extention, only csv file is accepted.");
            return;
        }
        let payload = {
            surveyId : this.state.surveyIdValue,
            bulkLinks : this.state.bulkLinks,
        };
        Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => {  return SurveysAPI.testuniqueLinks(payload); })
        .then((respoData: any) => { alert("Test unique links uploaded successfully."); this.setState({ status: PageStatus.Loaded, showTestUniqueLinkModal: false }, ()=>{this.fetchSurvey();}) })
        .catch((error) => { this.setState({ status: PageStatus.Error, error: error.message }); });
    }

    handleTestUniqueLinksFile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const jsonData = await this.convertCsvToJson(file);
                const transformedData = jsonData.map(this.modifiedConvertedJson);
                const filteredData = transformedData.filter(item => item !== null || item == 'undefined');
                if(filteredData.length < 1){
                    alert("Check file extention, only csv file is accepted.");
                    return;
                }
                this.setState({ bulkLinks:  filteredData  });
            } catch (error) {
                console.error('Error converting CSV to JSON:', error);
            }
        }
    };

    convertCsvToJson = (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const csvContent: any = reader.result

                if (!csvContent.trim()) {
                    reject(new Error('CSV content is empty.'));
                    return;
                }

                csvtojson()
                    .fromString(csvContent)
                    .then(jsonData => resolve(jsonData))
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsText(file);
        });
    };

    modifiedConvertedJson = (obj) => {
        if(obj.link) {
            return { link: obj.link };
        }
        return null;
    }

    uploadFileModal = (id, type) => {
        this.setState({showUploadLinkModal: true, selectedPartner4TestLink: id, sendLinksTime: type,});
    }

    downloadFileFunc = (partnerId):Promise<void> => {
        let payload = { surveyId: this.state.surveyIdValue, partnerId };
        return Promise.resolve()
        .then(()=> this.setState({status: PageStatus.Loading}))
        .then(()=>{ return SurveysAPI.downloadPartnerUniqueLinks(payload) })
        .then((respo)=> { this.setState({ status: PageStatus.Loaded }, () => exportToExcel(respo, `${partnerId} & Partner Links`)) })
        .catch((error)=> { this.setState({status: PageStatus.Error, error: error.message})})

    }

    downloadPartnerUsersFunc = (partnerId):Promise<void> => {
        let payload = { id: this.state.surveyIdValue, partnerId };
        return Promise.resolve()
        .then(() => { this.setState({ status: PageStatus.Loading })})
        .then(() => { return SurveysAPI.getPartnerUsersexport(payload)})
        .then((respo) => { this.setState({ status: PageStatus.Loaded }); respo.length < 1 ? alert("No users found.") : exportToExcel(respo, `${partnerId} & Partner users`) })
        .catch((error) => { this.setState({ status: PageStatus.Error, error: error.message })})
    }

    handleUploadfile = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const jsonData = await this.convertCsvToJson(file);
                const transformedData = jsonData.map(this.modifiedFileConvertedJson);
                this.setState({ bulkUploadLinkData: transformedData });
            } catch (error) {
                console.error('Error converting CSV to JSON:', error);
            }
        }
    };

    modifiedFileConvertedJson = (obj) => { return this.state.sendLinksTime == "firstTimeSend" ? { link: obj.link, dxUserId: obj.dxUserId } : { link: obj.link, userId: obj.userId, dxUserId: obj.dxUserId } }

    uplaodPartnerLinkFunc = ():Promise<void> => {
        let payload = {partnerId: this.state.selectedPartner4TestLink, surveyId: this.state.surveyIdValue, partnerLinks: this.state.bulkUploadLinkData, };
        return Promise.resolve()
        .then(()=> this.setState({status: PageStatus.Loading}))
        .then(()=> { return this.state.sendLinksTime == "firstTimeSend" ? SurveysAPI.testuniqueLinksPartners(payload) : SurveysAPI.resendpartnerUserLinks(payload) })
        .then((respo)=> { this.setState({ status: PageStatus.Loaded, sendLinksTime: '', bulkUploadLinkData: [], selectedPartner4TestLink: '', showUploadLinkModal: false, }); alert("Links uploaded successfully.") })
        .catch((error)=>{ this.setState({ status: PageStatus.Error, error: error.message })})
    }

    fetchOlderCountries = ():Promise<void> => {
        let payload = { id: this.state.surveyIdValue, cloneType: this.state.cloneTypeValue};
        return Promise.resolve()
        .then(()=> this.setState({status: PageStatus.Loading}))
        .then(()=> SurveysAPI.getMarketsOfSurvey(payload))
        .then((respo)=> { this.setState({ status: PageStatus.Loaded, olderCountries: respo }, ()=> this.fetchCountryList()); })
        .catch((error)=>{ this.setState({ status: PageStatus.Error, error: error.message })})
    }

    fetchCountryList(): Promise<void> {
        return Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => MasterDataAPI.countryList('10'))
        .then((countries) => {
            const options = countries.map(country => ({
                label: country.name,
                value: country.name,
                shortName: country.shortName,
            }));
            options.sort((a, b) => {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            });
            this.setState({ countries: options, status: PageStatus.Loaded });
            if(this.state.olderCountries.length > 0){
                const option = options.filter(item => this.state.olderCountries.some((name) => item.label === name.country));
                let countryName = option.map((ele) => { return {name: ele.label, shortName: ele.shortName}});
                this.setState({selectedMoreCountryOption: option, newCountries: countryName, });
            }
        })
        .catch((error) => {
            this.setState({ error: error.message, status: PageStatus.Error });
        });
    }

    handleMoreCountryChange = async (selectedMoreCountryOption) => {
        let countryName = selectedMoreCountryOption.map((ele) => { return {name: ele.label, shortName: ele.shortName}});
        this.setState({ newCountries: countryName, selectedMoreCountryOption });
    };

    handlePageSizeChange = (newValue: any) => { this.setState({ pageSize: newValue, currentPageNumber: 1 }, () => { this.fetchSurvey() }); };
    handlePageNumberChange = (newValue: any) => { this.setState({ currentPageNumber: newValue }, () => { this.fetchSurvey() }); };

    render() {
        const { pageSize, currentPageNumber, totalResponseData, totalResponsePages } = this.state;
        return (
            <>
                <Modal
                    centered
                    size="xl"
                    backdrop="static"
                    onHide={this.props.onClose}
                    show
                    style={{ zIndex: 1201 }}
                >
                    <Modal.Header closeButton>
                        <h5 className="mb-0 mt-1">Details</h5>
                        <div
                            className="d-flex justify-content-end w-100 mr-2"
                        >
                            {this.state.survey?.surveyType == "Test" ? 
                            <>
                                {
                                    this.state.survey?.useUniqueLinks ? 
                                    <button
                                        type="button"
                                        className="btn-sm btn-info mr-2"
                                        onClick={() => this.showTestUniqueLinksModal()}
                                    >
                                        <FontAwesomeIcon
                                            icon={['fas', 'list']}
                                            className="mr-2"
                                        />
                                        Test Unique Links
                                    </button> :
                                    <>
                                        <button
                                            type="button"
                                            className="btn-sm btn-info mr-2"
                                            onClick={() => this.copyTestCurrentSurveyLink()}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'copy']}
                                                className="mr-2"
                                            />
                                            Copy Test Link
                                        </button> 
                                        <button
                                            type="button"
                                            className="btn-sm btn-info mr-2"
                                            onClick={() => this.testCurrentSurvey()}
                                        >
                                            <FontAwesomeIcon
                                                icon={['fas', 'list']}
                                                className="mr-2"
                                            />
                                            Test
                                        </button> 
                                    </>
                                }
                            </>
                            : null }

                                <button
                                    type="button"
                                    className="btn-sm btn-info mr-2"
                                    onClick={() => this.openModalFor("Individual")}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'clone']}
                                        className="mr-2"
                                    />
                                    Clone
                                </button>
                            
                            {this.state.survey && this.state.groupCloneVar == "Y" ?
                                <button
                                    type="button"
                                    className="btn-sm btn-info mr-2"
                                    onClick={() => this.openModalFor("Group")}
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'clone']}
                                        className="mr-2"
                                    />
                                    Group Clone
                                </button>
                                    : ''
                            }

                            <button
                                type="button"
                                onClick={() => this.props.onUpdate()}
                                className="btn-sm btn-primary mr-2"
                            >
                                <FontAwesomeIcon
                                    icon={['fas', 'edit']}
                                    className="mr-2"
                                />
                                Update
                            </button>

                            {this.state.survey?.pointAllocationType !== 'Auto' && this.state.survey?.surveyType === 'Close' ?
                            <>
                                <button
                                    type="button"
                                    onClick={() => this.setState({ showManual: !this.state.showManual })}
                                    className="btn-sm btn-primary mr-2"
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'upload']}
                                        className="mr-2"
                                    />
                                    Upload Incentive
                                </button>
                                <button
                                    type="button"
                                    onClick={() => this.downloadManualRewards()}
                                    className="btn-sm btn-success mr-2"
                                >
                                    <FontAwesomeIcon
                                        icon={['fas', 'download']}
                                        className="mr-2"
                                    />
                                    Download Incentive
                                </button>
                            </>
                                : ''
                            }

                            {this.state.survey && this.state.survey.surveypartners.length > 0 ?
                                <button type="button" className="btn btn-info ml-1" onClick={() => this.handlePartnerExport()}>Export Partner Users</button>
                                : ''
                            }


                        </div>
                    </Modal.Header>
                    <Modal.Body>

                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>

                        <Show when={this.state.status === PageStatus.Loaded && !!this.state.survey}>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Survey Id: </strong>
                                    {this.state.survey?.id}
                                </div>
                                <div className="col">
                                    <strong>Survey Unique Id: </strong>
                                    {this.state.users.length > 0 && this.state.users[0].assignUser ?
                                        this.state.users[0].assignUser.temporarySurveyLinkId : this.state.survey?.uniqueid}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Name: </strong>
                                    {this.state.survey?.name}
                                </div>
                                <div className="col">
                                    <strong>Company: </strong>
                                    {this.state.survey?.company}
                                </div>
                            </div>

                            {(this.state.survey?.parentid && <>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Parent Id: </strong>
                                    {this.state.survey?.parentid}
                                </div>
                                <div className="col"></div>
                            </div>
                            </> 
                        )}

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Project Name: </strong>
                                    {this.state.survey?.projectname}
                                </div>
                                <div className="col">
                                    <strong>Project Id: </strong>
                                    {this.state.survey?.projectId}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Start-End IP Validated: </strong>
                                    {this.state.survey?.validateStartEndIp === "Y" ? 'Yes': 'No'}
                                </div>
                                <div className="col">
                                    <strong>Country Validated: </strong>
                                    {this.state.survey?.validateCountry === "Y" ? "Yes" : "No"}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Active: </strong>
                                    {this.state.survey?.isActive === true ? 'Yes' : 'No'}
                                </div>
                                <div className="col">
                                    <strong>Description: </strong>
                                    {this.state.survey?.description}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Complete Points: </strong>
                                    {this.state.survey?.ceggPoints}
                                </div>
                                <div className="col">
                                    <strong>Start Date: </strong>
                                    {/* {this.state.survey?.publishDate} */}
                                    {moment(this.state.survey?.createdAt).format('MM/DD/YYYY HH:mm A')}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Over Quota Points: </strong>
                                    {this.state.survey?.overquota}
                                </div>
                                <div className="col">
                                    <strong>Terminate Points: </strong>
                                    {this.state.survey?.terminate}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Quality Terminate Points: </strong>
                                    {this.state.survey?.qualityterminate}
                                </div>
                                <div className="col">
                                    <strong>PM Name: </strong>
                                    {this.state.survey?.pmName}
                                </div>
                            </div>


                            <div className="row mt-2">
                                <div className="col">
                                    <strong>End Date: </strong>
                                    {/* {this.state.survey?.expiryDate} */}
                                    {moment(this.state.survey?.expiryDate).format('MM/DD/YYYY HH:mm A')}
                                </div>
                                <div className="col">
                                    <strong>IPolls Quota: </strong>
                                    {this.state.survey?.userLimitCutoff}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Client Quota: </strong>
                                    {this.state.survey?.userLimitCommitted}
                                </div>
                                <div className="col">
                                    <strong>Survey Status: </strong>
                                    {this.state.survey?.surveyType == "Open" ? "Live" : ""}
                                    {this.state.survey?.surveyType == "Hold" ? "On Hold" : ""}
                                    {this.state.survey?.surveyType == "Wait" ? "Ids Awaited" : ""}
                                    {this.state.survey?.surveyType == "Close" ? "End" : ""}
                                    {this.state.survey?.surveyType == "Test" ? "Test" : ""}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Point Allocation Type: </strong>
                                    {this.state.survey?.pointAllocationType}
                                </div>
                                <div className="col">
                                    <strong>Client: </strong>
                                    {this.state.survey?.client}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Survey Length: </strong>
                                    {this.state.survey?.surveyLength}
                                </div>
                                <div className="col">
                                    <strong>Company Logo: </strong>
                                    <img src={(this.state.survey?.companyLogo && this.state.survey?.companyLogo != "") ? this.state.survey?.companyLogo : "/assets/img/logo-white.png"} style={{ width: '50px', height: '40px', backgroundColor: "black", objectFit: "contain" }} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Speeder Limit (In minutes): </strong>
                                    {this.state.survey?.outlierCutoffTime}
                                </div>
                                <div className="col">
                                    <strong>Cost Per Interview: </strong>
                                    {this.state.survey?.costPerInterview}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Use Unique Links: </strong>
                                    {this.state.survey?.useUniqueLinks === true ? 'YES' : 'NO'}
                                </div>
                                <div className="col">
                                    <strong>Close Date: </strong>
                                    {this.state.survey?.closeDate}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>IP Unique: </strong>
                                    {this.state.survey?.ipUnique === true ? 'YES' : 'NO'}
                                </div>
                                <div className="col">
                                    <strong>Survey URL Identifier: </strong>
                                    {this.state.survey?.surveyUrlIdentifier}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Client IR: </strong>
                                    {this.state.survey?.clientir ?? "N.A."}
                                </div>
                                <div className="col">
                                    <strong>Send Survey By: </strong>
                                    {this.state.survey?.sms === "Y" ? 'SMS' : ''}
                                    {this.state.survey?.sms === "Y" ? ', ' : ''}
                                    {this.state.survey?.whatsapp === "Y" ? 'Whats-App' : ''}
                                    {this.state.survey?.whatsapp === "Y" ? ', ' : ''}
                                    {this.state.survey?.email === "Y" ? 'Email' : ''}
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Test URL: </strong>
                                    <a href={this.state.survey?.testurl} target="_blank" rel="noopener noreferrer">
                                        {this.state.survey?.testurl}
                                    </a>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Live URL: </strong>
                                    <a href={this.state.survey?.url} target="_blank" rel="noopener noreferrer">
                                        {this.state.survey?.url}
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">

                                    <strong>Complete URL: </strong>
                                    <a href={'https://indiapolls.proeffico.com/#/surveys/completed?surveyid={surveyid}&userid={userid}'} target="_blank" rel="noopener noreferrer">
                                        {'https://indiapolls.proeffico.com/#/surveys/completed?surveyid={surveyid}&userid={userid}'}

                                    </a>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Over Quota URL: </strong>
                                    <a href={'https://indiapolls.proeffico.com/#/surveys/overquota?surveyid={surveyid}&userid={userid}'} target="_blank" rel="noopener noreferrer">
                                        {'https://indiapolls.proeffico.com/#/surveys/overquota?surveyid={surveyid}&userid={userid}'}
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Terminate URL: </strong>
                                    <a href={'https://indiapolls.proeffico.com/#/surveys/terminate?surveyid={surveyid}&userid={userid}'} target="_blank" rel="noopener noreferrer">
                                        {'https://indiapolls.proeffico.com/#/surveys/terminate?surveyid={surveyid}&userid={userid}'}
                                    </a>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <strong>Quality Terminate URL: </strong>
                                    <a href={'https://indiapolls.proeffico.com/#/surveys/qualityterminate?surveyid={surveyid}&userid={userid}'} target="_blank" rel="noopener noreferrer">
                                        {'https://indiapolls.proeffico.com/#/surveys/qualityterminate?surveyid={surveyid}&userid={userid}'}
                                    </a>
                                </div>
                            </div>

                        </Show>

                        <div className="jumbotron bg-white p-3 border shadow-sm mt-3">
                            <div className='mb-3'>Add Partners</div>

                            <form>
                                <div className="row mt-2">
                                    <div className="col">
                                        <label htmlFor="text">Partners</label>
                                        <Select
                                            name='partners'
                                            id='partners'
                                            onChange={this.handlePartnersChange}
                                            value={this.state.selectedPartnerOption}
                                            isMulti
                                            options={this.state.partners}
                                        />
                                    </div>
                                </div>
                            </form>

                            <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                                <button type="button" className="btn btn-success" onClick={() => this.addPartners()}>Add partners</button>
                                <input
                                    type="checkbox"
                                    className="ml-3"
                                    checked={this.state.checkboxChecked}
                                    onChange={this.handleCheckboxChange}
                                />
                                <span className="ml-2">Include 2 Parameters</span>
                            </div>

                            {this.state.selectedPartnerOption &&
                                this.state.selectedPartnerOption.map((data, index) => (
                                    <div key={data.value} className="row mt-2">
                                        <div key={data.value} className="col">
                                            <strong key={data.value}>{data.label}: </strong>
                                            {`https://indiapolls.proeffico.com/#/partner?partnerid=${data.value}&surveyid=${this.state.surveyIdValue}&rid={respondent_id}${this.state.checkboxChecked ? `&svid={svar_id}` : ''
                                                }`}
                                        </div>
                                    </div>
                                ))}

                            {this.state.selectedPartnerOption && <div className="jumbotron bg-white p-1 mt-2 shadow-sm">
                                <h6 className='mt-4'>Partner Details:</h6>
                                <Table responsive size="sm" bordered>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Partner Name</th>
                                            <th>Survey Name</th>
                                            <th>Completed </th>
                                            <th>Terminated </th>
                                            <th>Over Quota </th>
                                            <th>Quality Terminated</th>
                                            <th>IR%</th>
                                            <th>Median LOI</th>
                                            {this.state.survey?.useUniqueLinks ? 
                                                <>
                                                    <th>Upload Links</th>
                                                    <th>Download Links</th>
                                                </>
                                                : ""
                                            }
                                            <th>Export User</th>
                                            {this.state.survey?.useUniqueLinks && this.state.survey?.surveyType != "Test" ? 
                                                <th>Resend User Links</th>
                                            : ""}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.partnerDetails.map((detail, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td><a
                                                        onClick={() => this.handleCreateClick(detail.partnerId)}
                                                        style={{ cursor: 'pointer', color: 'black' }}
                                                        onMouseOver={(e) => (e.currentTarget.style.color = 'blue')}
                                                        onMouseOut={(e) => (e.currentTarget.style.color = 'black')}
                                                    >
                                                        {detail.partner_name ?? "-"}
                                                    </a></td>
                                                    <td>{detail.survey_name ?? "-"}</td>
                                                    <td>{detail.completed ?? "-"}</td>
                                                    <td>{detail.terminated ?? "-"}</td>
                                                    <td>{detail.overquota ?? "-"}</td>
                                                    <td>{detail.qualityTerminated ?? "-"}</td>
                                                    <td>{detail.ir ?? "-"}</td>
                                                    <td>
                                                        {detail.median_loi && !isNaN(Number(detail.median_loi)) 
                                                            ? Number(detail.median_loi).toFixed(2) 
                                                            : "-"}
                                                    </td>
                                                    {
                                                        this.state.survey?.useUniqueLinks ? <>
                                                            <td className='text-center'>
                                                                <button className="btn btn-sm btn-warning" onClick={()=>{ this.uploadFileModal(detail.partnerId, "firstTimeSend")}}>
                                                                    <FontAwesomeIcon
                                                                        icon={['fas', 'upload']}
                                                                    />
                                                                </button>
                                                            </td>
                                                            <td className='text-center'>
                                                                <button className="btn btn-sm btn-success" onClick={()=>{ this.downloadFileFunc(detail.partnerId)}}>
                                                                    <FontAwesomeIcon
                                                                        icon={['fas', 'download']}
                                                                    />
                                                                </button>
                                                            </td>
                                                        </> : ""
                                                    }
                                                    <td className='text-center'>
                                                        <button className="btn btn-sm btn-success" onClick={()=>{ this.downloadPartnerUsersFunc(detail.partnerId)}}>
                                                            <FontAwesomeIcon
                                                                icon={['fas', 'download']}
                                                            />
                                                        </button>
                                                    </td>
                                                    {
                                                        this.state.survey?.useUniqueLinks && this.state.survey?.surveyType != "Test" ? 
                                                        <>
                                                            <td className='text-center'>
                                                                <button className="btn btn-sm btn-info" onClick={()=>{ this.uploadFileModal(detail.partnerId, "secondTimeSend")}}>
                                                                    <FontAwesomeIcon
                                                                        icon={['fas', 'upload']}
                                                                    />
                                                                </button>
                                                            </td>
                                                        </>
                                                             : ""
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <h6 className='text-center'>{this.state.partnerDetails.length > 0 ? "" : "No Data Found"}</h6>
                            </div>}
                            <Alert
                                variant="danger"
                                show={this.state.status === PageStatus.Error}
                            >
                                {this.state.error}
                            </Alert>
                        </div>

                        <Show when={this.state.users.length !== 0} >
                            <div className="mt-5">
                                <button type="button" className="btn btn-info ml-1" onClick={() => this.handleExport()}>Export Users</button>
                                <UsersPaginations surveyStatus={this.state.survey?.surveyType} users={this.state.users} samples={this.state.samples} totalResponseData={totalResponseData} currentPageNumber={currentPageNumber} pageSize={pageSize}
                                    onSampleChange={(sample) => {
                                        this.setState({ selectedSample: sample })
                                    }} />
                                {totalResponsePages && totalResponseData && <div>
                                    <PaginationNumbering
                                        currentPage={currentPageNumber}
                                        returnPagenumber={this.handlePageNumberChange}
                                        showTotalData={totalResponseData}
                                        sendLastPage={totalResponsePages}
                                    />
                                </div>}
                            </div>
                        </Show>

                        {this.state.survey?.surveyType != "Test" ? 
                            <>
                                <DashboardTemplates id={this.state.surveyIdValue} />
                                <SurveyEmailSchedule id={this.state.surveyIdValue} isUnique={this.state.survey?.useUniqueLinks} />
                            </>
                        : null
                        }
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.showCloneModal}  
                    onHide={() => this.setState({ showCloneModal: false, olderCountries: [] })}
                    backdrop="static"
                    centered
                    size="md"
                    style={{ zIndex: 1201, background: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.cloneTypeValue} Clone
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="name">SMS/Whatsapp Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder='Enter here'
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="projectName">Project Name*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="projectName"
                                    name="projectName"
                                    value={this.state.projectName}
                                    onChange={(e) => this.setState({ projectName: e.target.value })}
                                    placeholder='Enter here'
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="projectId">Project Id*</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="projectId"
                                    name="projectId"
                                    value={this.state.projectId}
                                    onChange={(e) => this.setState({ projectId: e.target.value })}
                                    placeholder='Enter here'
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor='countryNewTitle'>Add New Market*</label>
                                <Select
                                name='countryNewTitle'
                                id='countryNewTitle'
                                onChange={this.handleMoreCountryChange}
                                value={this.state.selectedMoreCountryOption}
                                required
                                options={this.state.countries}
                                isMulti
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.cloneThisSurvey()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showUploadLinkModal}  
                    onHide={() => this.setState({ showUploadLinkModal: false, bulkUploadLinkData: [], selectedPartner4TestLink: '', sendLinksTime: '' })}
                    backdrop="static"
                    centered
                    size="md"
                    style={{ zIndex: 1201, background: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Upload File
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="uploadfile">File*</label>
                                <input
                                    type="file"
                                    accept='.csv'
                                    className="form-control"
                                    id="uploadfile"
                                    name="uploadfile"
                                    onChange={(e) => this.handleUploadfile(e)}
                                    placeholder='Enter here'
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={() => this.downloadSampleLinks()}>
                            Download Sample
                        </Button>
                        <Button variant="primary" onClick={() => this.uplaodPartnerLinkFunc()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.showTestUniqueLinkModal}  
                    onHide={() => this.setState({ showTestUniqueLinkModal: false })}
                    backdrop="static"
                    centered
                    size="md"
                    style={{ zIndex: 1201, background: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Test Unique Links
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Show when={this.state.status === PageStatus.Loading}>
                            <div className="d-flex justify-content-center w-100 p-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Show>
                        <div className="row mt-2">
                            <div className="col">
                                <label htmlFor="bulkLinks">Upload file*</label>
                                <input
                                    type="file"
                                    accept='.csv'
                                    className="form-control"
                                    id="bulkLinks"
                                    name="bulkLinks"
                                    onChange={(e) => this.handleTestUniqueLinksFile(e)}
                                    placeholder='Enter here'
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={() => this.downloadSampleLinks('sample')}>
                            Download sample file
                        </Button>
                        <Button variant="primary" onClick={() => this.submitTestUniqueLinksFile()}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Show when={this.state.showManual}>
                    <ManualSurveyRewards
                        surveyId={this.state.surveyIdValue}
                        show={this.state.showManual}
                        onClose={() => this.setState({ showManual: false, id: '' })}
                    />
                </Show>
                {/* part 3 */}
                {this.state.showModal && (
                    <Form
                        show={this.state.showModal}
                        id={this.state.partnerId}
                        onClose={this.handleCloseModal}
                        onSubmit={this.handleSubmit}
                        partnerName={this.state.partnerName}
                        partnerId={this.state.partnerId}
                        surveyId={this.state.surveyIdValue}
                    />
                )}
            </>
        );
    }
}

const DashboardDetailsWithRouter = withRouter(DashboardDetails);

export { DashboardDetailsWithRouter as DashboardDetails };
