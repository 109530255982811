import * as _ from 'lodash';

import { api } from '../axiosConfig';
import {surveysURL, surveyEmailSchedule, samplesURL, resendSurveyEmailSchedule} from "../Utils/urls";

export class SurveysAPI {
    static getAll(
        limit : any,
        pagenumber: number,
        payload: { userAuthId: string; } & any,
        offset = 0,
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getAll/${limit}/${pagenumber}`, payload).then((res) => {  
            return _.get(res, 'data', []);
        });
    }

    static create(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/create`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOne(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getOne/${id}`, {})
        .then((res) => {
            return _.get(res, 'data', {});
        });
    }

    static getOneDetails(
        id : string,
        limit : any,
        pagenumber: number,
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getOneDetails/${id}/${limit}/${pagenumber}`, {})
        .then((res) => {
            return _.get(res, 'data', {});
        });
    }

    static getpantnerdetailsmain(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getpantnerdetailsmain/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static getAllPartnerUsersexport(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getAllPartnerUsersexport/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static getSurveyDetailsExport(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getSurveyDetailsExport/${id}`, {})
        .then((res) => {
            return _.get(res, 'data', {});
        });
    }

    static update(id: string, payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/update/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOne(id: string): Promise<any> {
        return api.post(`${surveysURL}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static addPartners(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/add-partners`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }



    static getAllTemplate(
        id: string,
        limit = 100000,
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getAll-template/${id}/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static createTemplate(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/create-template`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOneTemplate(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveysURL}/getOne-template/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static updateTemplate(id: string, payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/update-template/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteOneTemplate(id: string): Promise<any> {
        return api.post(`${surveysURL}/delete-template/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }



    static getAllEmailSchedule(
        id: string,
        limit = 100000,
    ): Promise<any[]> {
        return api.post(`${surveyEmailSchedule}/getAll/${id}/${limit}`, {})
        .then((res) => {
            return _.get(res, 'data.data', []);
        });
    }

    static createEmailSchedule(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveyEmailSchedule}/create`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static getOneEmailSchedule(
        id : string
    ): Promise<any[]> {
        return api.post(`${surveyEmailSchedule}/getOne/${id}`, {})
        .then((res) => {
            return _.get(res, 'data.data', {});
        });
    }

    static updateEmailSchedule(id: string, payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveyEmailSchedule}/update/${id}`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static deleteEmailSchedule(id: string): Promise<any> {
        return api.post(`${surveyEmailSchedule}/delete/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static sendInviteNow(id: string): Promise<any> {
        return api.post(`${surveyEmailSchedule}/sendNow/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static downloadUsers(id: string): Promise<any> {
        return api.post(`${samplesURL}/getOneSampleAllUsers/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }


    static panelistSurveys(id: string): Promise<any> {
        return api.post(`${surveysURL}/panelist-surveys/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static panelistOneSurvey(id: string): Promise<any> {
        return api.post(`${surveysURL}/panelist-one-surveys/${id}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static GetUserOneAssignedSurveyCallback(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/GetUserOneAssignedSurveyCallback`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static surveyPartner(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/createSurveyPartnerUser`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static userRespondentDashboardWeb(userId: any, language = 'en'): Promise<any> {
        return api.post(`${surveysURL}/userRespondentDashboardWeb/${userId}?language=${language}`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static adminRespondentDashboardWeb(): Promise<any> {
        return api.post(`${surveysURL}/adminRespondentDashboardWeb`, {})
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static partnerSurveyUsers(selectPartners, surveyId): Promise<any> {
        return api.post(`${surveysURL}/getAllPartnerUsers/${selectPartners}/${surveyId}`, {})
            .then((res) => {
                return _.get(res, 'data.data', []);
            });
    }

    static uploadBulkRewards(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/uploadBulkRewards`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }

    static fetchSurveyOverviewStatus(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${surveysURL}/surveyOverviewStatus`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            });
    }
    static ResendReminderApi(payload: { userAuthId: string; } & any): Promise<any> {
        return api.post(`${resendSurveyEmailSchedule}/resendsendNow`, payload)
            .then((res) => {
                // Return the response data directly
                return res.data;
            })
            .catch((error) => {
                // Handle the error appropriately
                console.error("Error in ResendReminderApi:", error);
                throw error; // Rethrow the error to be handled in the calling function
            });
    }


    static deletePartner(id: string): Promise<any> {
        const payload = {
            partnerId: id,
        };
        return api.post(`${surveysURL}/destroy-Partner`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error deleting partner:', error.message);
                throw error; // Rethrow the error for further handling
            });
    }

    static surveysamplecount(surveyId: string, sampleId: string): Promise<any> {
        return api.post(`${surveysURL}/surveysamplecount`, {surveyId, sampleId, })
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error survey sample count:', error.message);
                throw error;
            });
    }

    static duplicatesurvey(payload: any): Promise<any> {
        return api.post(`${surveysURL}/duplicatesurvey`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error duplicate survey:', error.message);
                throw error;
            });
    }

    static testSurvey(payload: any): Promise<any> {
        return api.post(`${surveysURL}/testSurvey`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error test survey:', error.message);
                throw error;
            });
    }

    static testuniqueLinks(payload: any): Promise<any> {
        return api.post(`${surveysURL}/testuniqueLinks`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error test unique links :', error.message);
                throw error;
            });
    }

    static testuniqueLinksPartners(payload: any): Promise<any> {
        return api.post(`${surveysURL}/testuniqueLinksPartners`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error test unique links partner :', error.message);
                throw error;
            });
    }

    static resendpartnerUserLinks(payload: any): Promise<any> {
        return api.post(`${surveysURL}/resendpartnerUserLinks`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error test unique links partner :', error.message);
                throw error;
            });
    }

    static getMarketsOfSurvey(payload: any): Promise<any> {
        return api.post(`${surveysURL}/getMarketsOfSurvey`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error get markets of survey :', error.message);
                throw error;
            });
    }

    static downloadPartnerUniqueLinks(payload: any): Promise<any> {
        return api.post(`${surveysURL}/downloadPartnerUniqueLinks`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error download test unique links partner :', error.message);
                throw error;
            });
    }

    static getPartnerUsersexport(payload: any): Promise<any> {
        return api.post(`${surveysURL}/getPartnerUsersexport`, payload)
            .then((res) => {
                return _.get(res, 'data.data', {});
            })
            .catch((error) => {
                console.error('Error download test unique links partner :', error.message);
                throw error;
            });
    }
    
}
